import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSharedWith } from "src/store/reducers/sharing-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { cancelReview, sendForReview } from "src/store/actions/reviews";
import {
  exportEmail,
  importEmail,
  deleteEmail,
  uploadNewVersion,
  cloneEmail,
  fetchEmails,
  updateEmail,
} from "src/store/actions/emails";

import { share } from "src/store/actions/sharing";

import { downloadPdfByImages, getImageSize } from "src/services/utils";

//Dialogs
import ExportEmail from "./Export";
import UploadNewVersion from "../UploadNewVersion";
import DetailsDrawer from "../DetailsDrawer";
import SharingSettings from "../SharingSettings";
import SendForReview from "../SendForReview";
import UploadNew from "../UploadNew";
import Confirm from "../Confirm";
import AddNew from "./AddNew/AddNew";
import ReviewDelete from "../ReviewDelete";
import { useSearchParams } from "react-router-dom";
import RenameDialog from "../RenameDialog";
import { setEmail } from "src/store/reducers/emails-slice";
import UpdateEmailDetails from "./UpdateEmailDetails";
import { toDataURL, useConvertFileToBase64 } from "src/hooks/useUrlToBase64";
import CreateFolder from "../../folder/CreateFolder";


const EmailMyLibraryDialogs = (props) => {
  const dispatch = useDispatch();
  const type = "email";
  const { company, _id: userId } = useSelector((state) => state.auth.userData);
  const { onClose, dialog, emailId } = props;
  const emails = useSelector((state) => state.emails.list);
  const emailIndex = emails.findIndex((email) => email._id === emailId);
  const email = emails.find((email) => email._id === emailId);
  const [searchParams] = useSearchParams();
  const limit = 10;
  const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
  const skip = (pageNo - 1) * limit;

  const params = {};
  searchParams.forEach((value, key) => {
    if (key !== "page") params[key] = value;
  });

  params.skip = skip;
  params.limit = limit;

  //upload new email function
  const onUploadNewVersion = useCallback(
    (data) => {
      dispatch(uploadNewVersion(data));
    },
    [emailId]
  );

  //import new email
  const onUploadNew = useCallback((data) => {
    dispatch(importEmail(data));
  }, []);

  //export email
  const onExport = useCallback( async () => {
    if(email){
      const templateImageUrl = `${email.bucketURL}/${email.image}`;
      const imageSize = await getImageSize(templateImageUrl)
      const dataImage = await toDataURL({src:templateImageUrl, type:'image'});
      downloadPdfByImages([dataImage], imageSize, email?.name)
    }
  }, [email]);

  
  //get all company users
  const getCompanyUsers = useCallback(() => {
    dispatch(getAllCompanyUsers(company.companyId));
  }, [company]);

  const emailShare = useCallback(
    (sharedWith, accessLevel) => {
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });

      const shared = sharedWith.map((user) => ({
        userId: user,
        role: accessLevel,
      }));

      dispatch(setSharedWith(shared));
      dispatch(
        share({
          id: emailId,
          data: { sharedWith: userIds, accessLevel },
          type,
        })
      ).then((res)=> {
        if(res.payload) {
          dispatch(setEmail({ emailIndex, data: { isShared: res.payload.sharedWith.length || userIds.length } }));
        }
      })
    },
    [emailId]
  );

  //assign email fro review
  const assignForReview = useCallback(
    (data) => {
      dispatch(sendForReview({ id: emailId, data }));
    },
    [emailId]
  );

  const emailDelete = useCallback(async () => {
    await dispatch(deleteEmail(emailId));
    dispatch(fetchEmails(params));
    onClose();
  }, [emailId]);

  const cancelReviewEmail = useCallback(() => {
    dispatch(cancelReview({ id: emailId, type }));
    onClose();
  }, [emailId]);

  const handleClone = useCallback(async() => {
    await dispatch(cloneEmail(emailId));
    dispatch(fetchEmails(params));
    onClose();
  }, [emailId]);
  
  //rename presentation dialog
  const [renameLoading, setRenameLoading] = useState(false)
  const handleRenameEmail = (name)=>{
    setRenameLoading(true)
    dispatch(updateEmail({ id: emailId, data: {name: name} })).finally(()=>{
      setRenameLoading(false);
      if(emailIndex > -1){
        dispatch(setEmail({emailIndex:emailIndex, data:{name:name}}));
      }
      onClose();
    })
  }

  return (
    <>
    <CreateFolder show={dialog.createfolder} close={onClose} />
      <AddNew show={dialog.createNew} close={onClose} />
      <UpdateEmailDetails  show={dialog.updateEmailDetails} close={onClose} email={email} emailIndex={emailIndex}/>
      <ExportEmail
        isOpen={dialog.export}
        exportAs="pdf"
        handleExport={onExport}
        handleClose={onClose}
        id={emailId}
      />
      <UploadNewVersion
        title="Upload New Version"
        close={onClose}
        show={dialog.uploadNewVersion}
        onUpload={onUploadNewVersion}
        id={emailId}
      />
      {email && (
        <DetailsDrawer
        type="email"
        open={props.dialog.info}
        onClose={onClose}
        details={email}
        isEmail={true}
      />
      )}
      
      <SharingSettings
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.share}
        handleClose={onClose}
        onShare={emailShare}
        shareType={type}
      />
      <SendForReview
        type="email"
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.review}
        handleClose={onClose}
        onSend={assignForReview}
      />
      <UploadNew
        show={dialog.uploadNew}
        close={onClose}
        title="Upload From"
        type="Email"
        onUpload={onUploadNew}
        targetSystem
      />
      <Confirm
        isOpen={dialog.delete}
        subtitle="Are you sure you want to delete this email template?"
        handleClose={onClose}
        handleConfirm={emailDelete}
      />
           <ReviewDelete
       isOpen={dialog.reviewdelete}
        subtitle="Content under review or already reviewed cannot be deleted. Thank you for your understanding!"
        handleClose={onClose}
        
      />
      <Confirm
        isOpen={dialog.cancelReview}
        subtitle="Are you sure you want to cancel this review?"
        handleClose={onClose}
        handleConfirm={cancelReviewEmail}
      />
      <Confirm
        isOpen={dialog.clone}
        subtitle="Are you sure you want to clone this email template?"
        handleClose={onClose}
        handleConfirm={handleClone}
      />

      <RenameDialog
        isOpen={dialog.renameEmail}
        title="Email"
        handleClose={onClose}
        handleConfirm={handleRenameEmail}
        name={email && email.name}
        loading={renameLoading}
      />
    </>
  );
};

export default EmailMyLibraryDialogs;
