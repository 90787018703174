import React, { useState, useEffect } from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import {
  Grid,
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccounts,
  fetchContactById,
  updateContact,
} from "src/store/actions/campaign";
import { languages, countries } from "../../../data/data";

const UpdateContact = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);

  const campaignContactDetail = useSelector(
    (state) => state.campaign.contactDetail
  );
  const [contactDetails, setContactDetails] = useState(campaignContactDetail);

  useEffect(() => {
    setContactDetails(campaignContactDetail);
  }, [campaignContactDetail]);

  useEffect(() => {
    dispatch(fetchContactById(id));
    dispatch(fetchAccounts()).then((res) => {
      setAccounts(res.payload);
    });
  }, [dispatch]);

  const submitUpdateContact = (e) => {
    e.preventDefault();
    const data = {
      firstName: e.target.firstName.value,
      middleName: e.target.middleName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      mobile: e.target.mobile.value,
      phone: e.target.phone.value,
      fax: e.target.fax.value,
      lang: e.target.lang.value,
      account: e.target.account.value,
      address: {
        street: e.target.street.value,
        country: e.target.country.value,
        state: e.target.state.value,
        city: e.target.city.value,
        postalCode: e.target.postalCode.value,
      },
    };
    const id = contactDetails._id;
    dispatch(updateContact({ id, data }));
    navigate("/campaign/contacts");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((pre) => ({ ...pre, [name]: value }));
  };

  const addressHandler = (e) => {
    const { name, value } = e.target;
    setContactDetails((pre) => ({
      ...pre,
      address: { ...pre.address, [name]: value },
    }));
  };
  return (
    <>
      <Grid container gap={4}>
        <Grid item xs={12}>
          <Content title="Update Contact">
            <Card classes="campaign">
              <form onSubmit={submitUpdateContact}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Input
                      name="firstName"
                      className="input_box"
                      labelTag="First Name"
                      required={true}
                      value={contactDetails.firstName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Input
                      name="middleName"
                      className="input_box"
                      labelTag="Middle Name"
                      value={contactDetails.middleName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="lastName"
                      className="input_box"
                      labelTag="Last Name"
                      required={true}
                      value={contactDetails.lastName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Input
                      disabled={true}
                      name="email"
                      className="input_box"
                      labelTag="Email"
                      required={true}
                      type="email"
                      value={contactDetails.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="mobile"
                      type="number"
                      className="input_box"
                      labelTag="Mobile "
                      value={contactDetails.mobile}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="phone"
                      type="number"
                      className="input_box"
                      labelTag="Phone"
                      value={contactDetails.phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="fax"
                      className="input_box"
                      labelTag="Fax"
                      value={contactDetails.fax}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Language </Typography>
                    <FormControl className="input_box" fullWidth>
                      <Select
                        name="lang"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          contactDetails.length !== 0 ? contactDetails.lang : ""
                        }
                        onChange={handleChange}
                      >
                        {languages &&
                          languages.map((data, index) => {
                            return (
                              <MenuItem
                                value={`${data.name}`}
                                key={`${index}`}
                              >{`${data.name}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="street"
                      className="input_box"
                      labelTag="Street "
                      value={contactDetails.address.street}
                      onChange={addressHandler}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Country </Typography>
                    <FormControl className="input_box" fullWidth>
                      <Select
                        name="country"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          contactDetails.length !== 0
                            ? contactDetails.address.country
                            : ""
                        }
                        onChange={addressHandler}
                      >
                        {countries &&
                          countries.map((data, index) => {
                            return (
                              <MenuItem
                                value={`${data.name}`}
                                key={`${index}`}
                              >{`${data.name}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="state"
                      className="input_box"
                      labelTag="State "
                      value={contactDetails.address.state}
                      onChange={addressHandler}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="city"
                      className="input_box"
                      labelTag="City "
                      value={contactDetails.address.city}
                      onChange={addressHandler}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name="postalCode"
                      className="input_box"
                      labelTag="Postal Code "
                      value={contactDetails.address.postalCode}
                      onChange={addressHandler}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Select Account <span className="required">*</span></Typography>
                    <FormControl required className="input_box" fullWidth>
                      <Select
                        name="account"
                        value={
                          accounts.length !== 0 ? contactDetails.account : ""
                        }
                        onChange={handleChange}
                      >
                        {accounts &&
                          accounts.map((data) => {
                            return (
                              <MenuItem
                                value={`${data._id}`}
                                key={`${data._id}`}
                              >{`${data.name}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} className="d-flex btn-align">
                    <Box>
                      <Button
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        type="submit"
                        className="campaignBtn"
                      >
                        Update Contact
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Content>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateContact;
