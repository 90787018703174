import React from "react";
import Confirm from "src/components/dialogs/Confirm";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHistory } from "src/hooks/editor/history";
import { editorActions } from "src/store/reducers/editor-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";
import $ from "jquery";
import { generateAndAppendMediaQueries } from "../../microsites/renderMicrosite";
import { isEditor } from "src/services/editor.service";
const DeleteLayerDialogs = () => {
  const dispatch = useDispatch();
  const { updateHistory } = useEditorHistory();
  const { requestHierarchyUpdate } = useEditorHeirarchy();
  const { activeEditable: { elementId }, dialogs } = useSelector((state) => state.editor);
  const isDeleteLayerDialog = dialogs.deleteLayer
 
  const closeDialog = () => {
    dispatch(editorActions.setEditorDialog({dialog: 'deleteLayer', value: false}));
  };

  const deleteLayer = () => {
    if (elementId) {
      const element = document.getElementById(elementId);
  
      // Update history
      updateHistory({ action: HISTORY_ACTIONS.deleted });
  
      // Check if element exists and handle conditional deletion
      if (element && $("#" + elementId).attr("class").includes("popup")) {
        $("#" + elementId)
          .next(".overlay")
          .remove();
        $("#" + elementId).remove();
      } else if (element) {
        $("#" + elementId).remove();
      } else {
        console.warn("Element with ID:", elementId, "not found.");
      }
  
      // Deactivate element and update hierarchy
      dispatch(editorActions.deActiveElement());
      requestHierarchyUpdate();
  
      // Microsite-specific logic
      const isMicrosite = isEditor.microsite();
      if (isMicrosite && element && element.classList.contains("column-component")) {
        generateAndAppendMediaQueries();
      }
  
      return true;
    } else {
      console.warn("Element ID is not provided.");
      return false;
    }
  };
  

  return (
    <Confirm
      isOpen={isDeleteLayerDialog}
      handleClose={closeDialog}
      handleConfirm={deleteLayer}
      title="Are You Sure?"
      subtitle="You want to delete this layer"
    ></Confirm>
  );
};

export default DeleteLayerDialogs;
