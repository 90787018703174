import React, { useState, useEffect } from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import {
  Grid,
  Button,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  createGroup,
  fetchAccounts,
  fetchContactsByAccount,
} from "src/store/actions/campaign";
import { useNavigate } from "react-router-dom";
import { uiActions } from "src/store/reducers/ui-slice";

const CreateGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contacts, setContacts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");

  //accounts
  useEffect(() => {
    dispatch(fetchAccounts()).then((res) => {
      setAccounts(res.payload);
    });
  }, [dispatch]);

  const accountHandler = (event, newValue) => {
    if (newValue) {
      dispatch(fetchContactsByAccount(newValue._id)).then((res) => {
        setUsers([]);
        setContacts(res.payload);
      });
      setSelectedAccount(newValue._id);
    }
  };
  
  const submitCreateGroup = async (e) => {
    e.preventDefault();
    const usersData = [];
    users.map((user) => usersData.push({ userId: user._id, firstName :user.firstName, lastName:user.lastName, email: user.email }));
    const data = {
      name: e.target.name.value,
      accountId: selectedAccount,
      users: usersData,
    };
    await dispatch(createGroup(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/campaign/groups");
      } else {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Group Error!",
            message: `Group Name Already Exists`,
          })
        );
      }
    });
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Content title="Create Group">
          <Card classes="campaign">
            <form onSubmit={submitCreateGroup}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Input
                    name="name"
                    className="input_box"
                    labelTag="Name"
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>Select Account <span className="required">*</span></Typography>
                  <Autocomplete
                    options={accounts}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <Box>
                          <Typography className="option-label">
                            {option.name}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    onChange={accountHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search for a Account"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Contacts <span className="required">*</span></Typography>
                  <Autocomplete
                    multiple={true}
                    filterSelectedOptions={true}
                    options={contacts}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option._id}>
                        <Box>
                          <Typography className="option-label">
                            Contact Name : {option.firstName} {option.lastName}
                          </Typography>
                          <Typography>Email : {option.email}</Typography>
                        </Box>
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      setUsers(newValue);
                    }}
                    value={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search for a Contact"
                        inputProps={{
                          ...params.inputProps,
                          required: users.length === 0,
                        }}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                  />
                </Grid>
                <Grid item md={12} display="flex" className="d-flex btn-align">
                  <Box>
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                      type="submit"
                      className="campaignBtn"
                    >
                      Create Group
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Content>
      </Grid>
    </Grid>
  );
};

export default CreateGroup;
