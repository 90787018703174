import React, { useState, useEffect, Fragment } from "react";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import {
  Grid,
  Button,
  Box,
  Typography,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  TextField,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchEmails } from "src/store/actions/emails";
import {
  createCampaign,
  createCampaignName,
  fetchGroups,
  updateCampaign,
} from "src/store/actions/campaign";
import { timezones } from "../../../data/data";
import { useNavigate } from "react-router-dom";
import { weekDays, monthDays } from "../../../data/data";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { uiActions } from "src/store/reducers/ui-slice";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const steps = ["Campaign Info", "Create schedule", "Template and groups"];

export default function CampaignForm({
  editCampaignId,
  campaignDetails,
  onFinish,
}) {
  const dispatch = useDispatch();
  const currentDate = new Date().getDate();
  const [isLoading, setIsloading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [createdCampaignId, setCreatedCampaignId] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [groups, setGroups] = useState([]);
  const [emails, setEmails] = useState([]);
  const [url, setUrl] = useState(null);
  
  const [data, setData] = useState({
    name: "",
    timezone: "",
    timePeriod: "",
    weekDays: [],
    date: "",
    endDate:"",
    time: "",
    groups: [],
    emailTemplate: null,
  });

  const findTemplete = (id) => {
    if (!id) return null;
    return emails.find((email) => email._id === id) || null;
  };

  const findGroups = (groupsData = []) => {
    return groupsData.map((groupData) => {
      return groups.find((group) => group._id === groupData.groupId);
    });
  };

  const onChangeHandler = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    dispatch(fetchEmails({ limit: 1000, skip: 0 })).then((res) => {
      if (res.payload.response) {
        setEmails(res.payload.response);
      }
    });
    dispatch(fetchGroups()).then((res) => {
      setGroups(res.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    if (editCampaignId && campaignDetails) {
      console.log(campaignDetails, "details");

      const emailTemplate = findTemplete(campaignDetails.template?.templateId);

      setData({
        name: campaignDetails.name,
        timezone: campaignDetails.timezone,
        timePeriod: campaignDetails.timePeriod,
        weekDays: campaignDetails.weekDays,
        endDate: campaignDetails.endDate,
        date: campaignDetails.date
          ? +campaignDetails.date
          : campaignDetails.date,
        time: campaignDetails.time,
        groups: findGroups(campaignDetails.groups),
        emailTemplate: findTemplete(campaignDetails.template?.templateId),
      });

      const eTMP = campaignDetails?.template;
      const templateURL = eTMP
        ? `${eTMP.bucketURL}/${eTMP.sourceTemplate}/assets/images/image-thumb.jpg`
        : null;

      setUrl(templateURL);
    }
  }, [campaignDetails, emails, groups]);

  const getCampaignPayloadData = () => {
    const groupIdsAndName = data.groups.map((group) => ({
      groupId: group._id,
      name: group.name,
    }));

    const steps = ["info", "schedule", "templateAndGroups"];

    const weeklyKey =
      data.timePeriod == "weekly" ? { weekDays: data.weekDays } : {};
    const dateKey =
      data.timePeriod == "once" || data.timePeriod == "monthly"
        ? { date: data.date }
        : {};

    const endDateKey =
      data.timePeriod == "weekly" || data.timePeriod == "daily" || data.timePeriod == "monthly"
      ? {endDate: data.endDate}
        : {};


    const campaignData = {
      name: data.name,
      step: steps[activeStep],
      timezone: data.timezone,
      groups: groupIdsAndName,
      templateId: data.emailTemplate?._id || "",
      templateName: data.emailTemplate?.name || "",
      timePeriod: data.timePeriod,
      ...weeklyKey,
      time: data.time,
      ...dateKey,
      ...endDateKey,
    };

    return campaignData;
  };

  const submitCreateOrUpdateCampaign = async (isFinish = false) => {
    setIsloading(true);
    try {
      const data = getCampaignPayloadData();
      console.log(data)
      if (createdCampaignId || editCampaignId) {
        await dispatch(
          updateCampaign({
            data: data,
            id: editCampaignId || createdCampaignId,
          })
        ).unwrap();
      } else {
        const res = await dispatch(createCampaign(data)).unwrap();
        const id = res._id;
        setCreatedCampaignId(id);
      }

      if (isFinish) {
        return onFinish();
      }
      handleNext();
    } catch (e) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Campaign Error!",
          message: "Something went wrong",
        })
      );
    }
    setIsloading(false);
  };

  const isScheduleFilled = () => {
    if (!data.timezone || !data.timePeriod) {
      return false;
    }
    if (data.timePeriod === "daily") {
      return data.time !== "";
    }

    if (data.timePeriod === "weekly") {
      return data.weekDays.length !== 0 && data.time !== "";
    }

    if (data.timePeriod === "once" || data.timePeriod === "monthly") {
      return data.date && data.time !== "";
    }

    return false;
  };
   
  return (
    <Card classes="campaign">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

      {activeStep === 0 ? (
        <Fragment>
          <Box className="stepper-body">
            {createdCampaignId && (
              <Typography variant="caption">(Edit campaign name)</Typography>
            )}
            <Input
              className="input_box"
              placeholder="Enter campaign name"
              labelTag="Campaign Name"
              required={true}
              value={data.name}
              onChange={(e) => onChangeHandler("name", e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
            variant="contained"
            className="capitalize"
            endIcon={<NavigateNextIcon size={13} />}
            disabled={!data.name || isLoading}
            onClick={() => submitCreateOrUpdateCampaign()}
            >
              Next
            </Button>
          </Box>
        </Fragment>
      ) : activeStep === 1 ? (
        <Fragment>
          <Fragment>
            <Box className="stepper-body">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography>Timezone</Typography>
                  <FormControl required className="input_box" fullWidth>
                    <Select
                      name="timezone"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue=""
                      value={data.timezone}
                      onChange={(e) =>
                        onChangeHandler("timezone", e.target.value)
                      }
                    >
                      {timezones &&
                        timezones.map((data, index) => {
                          return (
                            <MenuItem
                              value={`${data.value}`}
                              key={`${data.label}`}
                            >{`${data.label}`}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6}>
                <Input
                  name="repeat"
                  labelTag="Repeat"
                  className="input_box"
                  type="number"
                  defaultValue="1"
                  disabled={selected === "once"}
                  required={true}
                />
              </Grid> */}
                <Grid item xs={6}>
                  <Typography>Time Period</Typography>
                  <FormControl required className="input_box" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue="weekly"
                      value={data.timePeriod}
                      onChange={(e) =>
                        onChangeHandler("timePeriod", e.target.value)
                      }
                    >
                      <MenuItem value={"daily"}>daily</MenuItem>
                      <MenuItem value={"weekly"}>weekly</MenuItem>
                      <MenuItem value={"monthly"}>monthly</MenuItem>
                      <MenuItem value={"once"}>once</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {data.timePeriod === "weekly" ? (
                  <>
                    <Grid item xs={6}>
                      <Typography>Select Week Days</Typography>
                      <FormControl required className="input_box" fullWidth>
                        <Select
                          name="weekDays"
                          multiple
                          displayEmpty
                          value={data.weekDays}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            const valueToSet =
                              typeof value === "string"
                                ? value.split(",")
                                : value;

                            onChangeHandler("weekDays", valueToSet);
                          }}
                          input={<OutlinedInput />}
                        >
                          {weekDays.map((data) => (
                            <MenuItem key={data.title} value={data.value}>
                              {data.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : null}
                
                {data.timePeriod === "monthly" || data.timePeriod === "once" ? (
                  <Grid item xs={6}>
                    <Typography>Select Date</Typography>
                    <FormControl required className="input_box" fullWidth>
                      <Select
                        value={data.date || currentDate}
                        onChange={(e) =>
                          onChangeHandler("date", e.target.value)
                        }
                        name="Date"
                        input={<OutlinedInput />}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                      >
                        {monthDays.map((mDays) => (
                          <MenuItem key={mDays.value} value={mDays.value} disabled={mDays.value < currentDate}>
                            {mDays.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}

                {data.timePeriod == "weekly" || data.timePeriod == "daily" || data.timePeriod == "monthly"?(
                  <Grid item xs={6}>
                     <Input
                    value={data.endDate}
                    onChange={(e) => onChangeHandler("endDate", e.target.value)}
                    className="input_box"
                    labelTag="End Date"
                    type="date"
                    required={true}
                    inputProps={{inputProps: { min: `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`} }}
                  />
                  </Grid>
                ):null}

                <Grid
                  item
                  xs={
                    data.timePeriod === "weekly" || data.timePeriod === "monthly"
                      ? 12
                      : 6
                  }
                >
                  <Input
                    value={data.time}
                    onChange={(e) => onChangeHandler("time", e.target.value)}
                    className="input_box"
                    labelTag="Select Time"
                    type="time"
                    required={true}
                  />
                </Grid>

                
              </Grid>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="outlined"
                className="capitalize"
                disabled={isLoading}
                color="inherit"
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
               variant="contained"
               className="capitalize"
               endIcon={<NavigateNextIcon size={13} />}
                disabled={!isScheduleFilled() || isLoading}
                onClick={() => submitCreateOrUpdateCampaign()}
              >
                Next
              </Button>
            </Box>
          </Fragment>
        </Fragment>
      ) : activeStep === 2 ? (
        <Fragment>
          <Fragment>
            <Box sx={{ marginTop: 2 }} className="stepper-body">
              <Grid container spacing={3}>
                <Grid sx={{ marginTop: 2 }} item xs={12}>
                  <Autocomplete
                    style={{ width: "auto" }}
                    options={emails}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option._id}>
                        <img
                          className="option-image"
                          src={option.bucketURL + "/" + option.thumbnail}
                          alt="thumb"
                        />
                        <Typography
                          className="option-label"
                          fontSize={"14px"}
                          fontWeight={700}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                    )}
                    value={data.emailTemplate}
                    onChange={(event, newValue) => {
                      onChangeHandler("emailTemplate", newValue);
                      newValue?.bucketURL
                        ? setUrl(`${newValue.bucketURL}/${newValue.thumbnail}`)
                        : setUrl(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search for a Email Template"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                  />
                </Grid>
                <Grid sx={{ marginTop: 2 }} item xs={12}>
                  <Autocomplete
                    multiple={true}
                    filterSelectedOptions={true}
                    options={groups}
                    getOptionLabel={(option) => option.name}
                    key={(option) => option._id}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option._id}>
                        <Typography className="option-label">
                          {option.name}
                        </Typography>
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      onChangeHandler("groups", newValue);
                    }}
                    value={data.groups}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search for a Groups"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {url && (
              <>
                <Stack direction={"column"} alignItems={"center"} gap={"10px"}>
                  <Typography>Email Preview</Typography>
                  <img src={url} alt="thumbnail" width={"100%"} />
                </Stack>
              </>
            )}

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="outlined"
                className="capitalize"
                color="inherit"
                disabled={activeStep === 0 || isLoading}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                 variant="contained"
                 className="capitalize"
                disabled={
                  !data.emailTemplate || data.groups.length === 0 || isLoading
                }
                onClick={() => submitCreateOrUpdateCampaign(true)}
              >
                Finish
              </Button>
            </Box>
          </Fragment>
        </Fragment>
      ) : null}
    </Card>
  );
}
