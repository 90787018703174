import {
  getHTMLFromBody,
  getLayersEditableChildren,
  insertHtmlToDropzone,
  editableActions,
  setLayerElementIds,
  deActiveElementOnOtherSideClick,
} from "src/services/editor.service";
import { getRandomId } from "src/services/utils";
import {
  csRenderFunctional,
  useSetLayerHeight,
} from "../editorLogics/components";
import $ from "jquery";

export const changeAssetsUrlOfHTML = (editorHtmlData, assetsBaseUrl, type = 'slide') => {
  let assetsUrlPinPoint = '';
  let timestamp = new Date().getTime();
  if (type === 'slide') {
    assetsUrlPinPoint = "assets/slides"
  } else {
    assetsUrlPinPoint = "assets/masterTemplates"
  }
  if (editorHtmlData && editorHtmlData.trim()) {

    let modifyHTML = editorHtmlData
      .replaceAll(assetsUrlPinPoint, assetsBaseUrl + assetsUrlPinPoint)
      .replaceAll("./", "");

    //well its added to add t variable to avoid image loading from cache,
    //it can be remove if image loaded again by onerror event
    // modifyHTML = modifyHTML.replace(/\.(png|jpeg|jpg)(?!\?t=)/g, (match) => {
    //   const uniqueTimestamp = Date.now() + Math.random().toString(36).substring(2, 8); // Generate a unique timestamp
    //   return `${match}?t=${uniqueTimestamp}`;
    // });

    return modifyHTML

  }
  return null;
};
export const PreloadImages = (reloads) => {
  reloads?.forEach((reload) => {
    const element = document.getElementById(reload.elementId)
    if(reload.elementType === 'background'){
      const computedStyle = window.getComputedStyle(element);
      if (computedStyle.backgroundImage) {
        const originalBackground = computedStyle.backgroundImage;
        element.style.backgroundImage = ''; // Temporarily reset
        element.style.backgroundImage = originalBackground; // Reapply
        element.setAttribute('data-reloads','reloaded');
        console.log('background force reload of this', element)
      }
    }
    if(reload.elementType === 'images'){
      if (element.src) {
        const originalSrc = element.src;
        const newSrc = `${originalSrc}?t=${Date.now()}`;
        element.src  = ''; // Temporarily reset
        element.src = newSrc; // Reapply
        element.setAttribute('data-reloads','reloaded');
        console.log('src force reload of this', element)
      }
    }
  })
}
export const applyPadding = (el) => {
  const layer = $(el);
  const editable = layer.find(".editable");
  const padding = 10;
  //now apply padding class on editable element
  const setPadding = (element) => {
    // if element has not styled padding
    if (!element.attr("style")?.includes("padding")) {
      if (element.find(".editable").length && !element.hasClass("cs-layer")) {
        element.css("padding", padding);
        //console.log(element)
      }
    }
  };
  // set for layer and child elements
  setPadding(layer);
  setTimeout(() => {
    useSetLayerHeight(layer);
  }, 250);

  if (editable.length || layer.hasClass("cs-col")) {
    setPadding(editable); // do not set for last nested editable
    setTimeout(() => {
      useSetLayerHeight(layer);
    }, 250);
  }
};

const attachlisteners = ({
  element,
  activeElement,
  updateHistory,
  requestHierarchyUpdate,
  forCapture,
}) => {
  const isDropzone = element.classList.contains("cs-slide");
  // Redering Main element (can be dropzone or a layer)
  element.classList.add("editable");
  if (isDropzone) {
    // <----------------------Dropzone------------------------>
    if (!element.getAttribute('data-Listener')) {
      element.addEventListener("click", (e) => {
        if (e.target.classList.contains('cs-slide')) {
          e.stopPropagation();
          editableActions.clickHandler({ element, activeElement });
        }
      });
      element.setAttribute('data-Listener', 'true')
    }
  } else {
    // <----------------------Layer------------------------>


    // element.setAttribute('tabindex', "0");
    // Click listener
    const addEventListeners = (element, isLayer) => {
      element.addEventListener("click", (e) => {
        element.focus()
        //stop click event if user dragging stop, to avoid useless active of child element, its will help to avoid history activeEditable issue
        if (element.closest('.cs-layer').classList.contains('dragging')) {
          return false
        }
        e.stopPropagation();
        editableActions.clickHandler({ e, element, activeElement });
      });

      // mouse listeners
      element.addEventListener("mouseover", (e) => {
        e.stopPropagation();
        editableActions.mouseOverHandler({ e, element });
      });
      element.addEventListener("mouseout", (e) => {
        editableActions.mouseOutHandler({ e, element });
      });
      if (isLayer) {
        editableActions.draggableAndResizeable({
          element,
          updateHistory,
          activeElement,
          onDragDropCallback: requestHierarchyUpdate,
          zoom: 1,
        });
      }

      // addition listeners
      if (element.classList.contains("text-component")) {
        element.addEventListener("keypress", function (e) {
          const getLayer = $(element).parent().parent();
          useSetLayerHeight(getLayer);
        });
      }
    };

    // rendering layer 2nd param, isLayer?
    addEventListeners(element, true);

    // Redering it's nested children if they are editable if not a dropzone
    const nestedEditables = getLayersEditableChildren(element);
    nestedEditables.forEach((nestedEditable) => {
      nestedEditable.classList.add("editable");
      addEventListeners(nestedEditable);
      csRenderFunctional(nestedEditable, forCapture);
      // if (nestedEditable.tagName === 'IMG') {
      //   nestedEditable.onerror = () => {
      //     console.warn('image could not load , trying reload it again', nestedEditable);
      //     // Retry loading the image with a new timestamp
      //     const src = nestedEditable.getAttribute("src").split("?")[0]; // Remove old query params
      //     const newSrc = `${src}?t=${Date.now()}`;
      //     nestedEditable.setAttribute("src", newSrc); // Assign the new src
      //   }
      // }

    });
  }
};

export const csRenderPresentationEditable = ({
  element,
  updateHistory,
  activeElement,
  requestHierarchyUpdate,
  forCapture,
}) => {
  // applying unique id
  if (!element.classList.contains("cs-slide")) {
    setLayerElementIds(element);
    useSetLayerHeight(element);

    if (!element.id) {
      element.id = getRandomId();
    }
  }

  // if not have status then append it first
  if (!element.querySelector(".status")) {
    element.insertAdjacentHTML(
      "beforeend",
      "<div class='status'><span></span></div>"
    );
  }
  // Preventing default for anchor tags
  const a = element.tagName === "A" ? element : element.querySelector("a");
  if (a) {
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // making resizeable , draggable
  if (element.getAttribute("class").includes("flipCard-component")) {
    setLayerElementIds(element);
  }
  if (element.getAttribute("class").includes("tabGroup-component")) {
    const tabLinks = element.querySelectorAll(".tabLinks");
    const tabContents = element.querySelectorAll(".cs-tabContent");
    setTimeout(() => {
      tabContents.forEach(
        (tab, index) => (tabLinks[index].dataset.target = tab.id)
      );
    }, 100);
  }

  // Adding listeners
  attachlisteners({
    element,
    activeElement,
    updateHistory,
    requestHierarchyUpdate,
    forCapture,
  });
};

export const renderEditableElements = ({
  layersContainer,
  activeElement = () => { },
  updateHistory = () => { },
  requestHierarchyUpdate = () => { },
  forCapture = true,
}) => {
  const csLayers = layersContainer.querySelectorAll(".cs-layer");

  // Rendering container itself first
  csRenderPresentationEditable({
    element: layersContainer,
    activeElement,
    updateHistory,
    requestHierarchyUpdate,
    forCapture,
  });
  // Rendering Layers
  csLayers.forEach((layer, i) => {
    // now bnding funcationality to layers
    csRenderPresentationEditable({
      element: layer,
      activeElement,
      updateHistory,
      requestHierarchyUpdate,
      forCapture,
    });
  });

  requestHierarchyUpdate();
};

export const renderSlide = ({
  dropzone,
  editorHtmlData,
  activeElement,
  updateHistory,
  requestHierarchyUpdate,
  deActiveElement,
} = {}) => {
  // Appending HTML
  insertHtmlToDropzone(dropzone, editorHtmlData);
  // Rendering with functionalities
  renderEditableElements({
    layersContainer: dropzone,
    activeElement,
    updateHistory,
    requestHierarchyUpdate,
  });

  return deActiveElementOnOtherSideClick(deActiveElement);
};
