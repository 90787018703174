import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Content from "src/layouts/Content";
import { createUser } from "src/store/actions/users";
import UsersForm from "./UsersForm";

const CreateUser = () => {
  const { company } = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = (createData) => {
    const data = { ...createData, company };
    return dispatch(createUser(data))
      .unwrap()
      .then(() => {
        navigate("/settings/users");
      });
  };

  return (
    <>
     <Content title="Create User">
      <UsersForm onSubmit={onSubmitHandler} cancelPath="/settings/users" type="create" />
      <Outlet />
    </Content>
    </>
  );
};

export default CreateUser;
