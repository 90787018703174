import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  FormHelperText,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  IoCheckbox,
  IoCheckmarkOutline,
  IoCloseOutline,
} from "react-icons/io5";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useDispatch } from "react-redux";
import Check_Box from "src/components/common/Check_Box";
import Main from "src/components/dialogs/Main";
import Divider from "src/components/ui/Divider";
import { updateUserData, changePassword } from "src/store/actions/auth";
import { useForm, Controller } from "react-hook-form";
import InputFields from "src/components/ui/formElements/InputFields";
import "./ChangePasswordDialog.scss";
import { IoMdCloseCircle, IoMdRemoveCircleOutline, IoMdSquare } from "react-icons/io";
import { validation } from "src/utils/Utils";
import Button from "src/components/Button";
const ChangePasswordDialog = ({ isDialogOpen, handleDialogClose }) => {
  const [loading, setLoading] = useState(false)
  const {
    watch,
    control,
    reset,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({defaultValues: {newPassword:'', newPasswordConfirm:''}});
  const {hasNumber, hasSpecialChar, hasUppercase} = validation
  
  const currentPassword = watch("currentPassword");
  const newPassword = watch("newPassword");
  const newPasswordConfirm = watch("newPasswordConfirm");
  

  const dispatch = useDispatch();


  const listErrors = (newPassword, newPasswordConfirm) =>{
    const errors = {
      characterLimit: 'default',
      upperCase: 'default',
      specialCharacter: 'default',
      oneNumber: 'default',
      matchPassword: 'default',
    }
    
    if(!newPassword || !newPasswordConfirm){
      return errors
    }
    
    if(newPassword.length === 0 || newPasswordConfirm.length === 0){
      errors.characterLimit = 'default'
      errors.specialCharacter = "default"
      errors.oneNumber = "default"
      errors.upperCase = "default"
      errors.matchPassword = "default"
    }

    //limit validation
    if((newPassword.length < 8 || newPassword.length > 20) || (newPasswordConfirm.length < 8 || newPasswordConfirm.length > 20)){
      errors.characterLimit = 'error'
    }else{
      errors.characterLimit = 'completed'
    }
    
    //special character
    if(hasSpecialChar(newPassword) && hasSpecialChar(newPasswordConfirm)){
      errors.specialCharacter = "completed";
    }else{
      errors.specialCharacter = "error";
    }

    //oneNumber 
    if(hasNumber(newPassword) && hasNumber(newPasswordConfirm)){
      errors.oneNumber = "completed";
    }else{
      errors.oneNumber = "error";
    }
    
    //hasUppercase
    if(hasUppercase(newPassword) && hasUppercase(newPasswordConfirm)){
      errors.upperCase = "completed";
    }else{
      errors.upperCase = "error";
    }
    
    //hasUppercase
    if(newPassword === newPasswordConfirm){
      errors.matchPassword = "completed";
    }else{
      errors.matchPassword = "error";
    }

    return errors;
  }
  
  const getErrorsList = listErrors(newPassword, newPasswordConfirm);
  
  const onSubmit = (data) => {
    setLoading(true)
    dispatch(changePassword({password: newPassword, prevPassword: currentPassword})).then((res)=>{
      if(res.meta.requestStatus !== 'rejected'){
        handleDialogClose()
      }else{
        setError("currentPassword", {
          type: "manual",
          message: 'Incorrect current password',
        });
      }
    }).finally(()=>{
      setLoading(false)
    })
  };

  const renderIcon = (type)=>{
    return (
      <>
      {getErrorsList[type] === 'default' ? (
      <IoMdSquare />
    ) : getErrorsList[type] === 'completed' ? (
      <IoCheckbox /> 
    ) : (
      <IoMdCloseCircle/>
    )}
      </>
    )
  }   
  
  useEffect(() => {
    return () => {
     if(isDialogOpen){
      reset()
     }
    }
  }, [isDialogOpen])
  
  useEffect(() => {
    if (newPasswordConfirm && newPasswordConfirm !== newPassword) {
      setError('newPasswordConfirm', {
        type: 'manual',
        message: 'Passwords do not match',
      });
    } else {
      clearErrors('newPasswordConfirm');
    }
  }, [newPassword, newPasswordConfirm, setError, clearErrors]);

  return (
    <Main
      isOpen={isDialogOpen}
      fullWidth={true}
      handleClose={handleDialogClose}
      maxWidth="500px"
      className="ChangePasswordDialog"
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3}>
          <Box className="form-header">
            <Typography component="h2" variant="h2">
              Update Your Password
            </Typography>
            <Divider sx={{ mt: 2 }}></Divider>
          </Box>

          <Box className="form-body">
            <InputFields
              type="password"
              control={control}
              fieldName="currentPassword"
              label="Current Password"
              rules={{
                required: "Current Password is required",
              }}
              error={errors?.currentPassword}
              placeholder="Enter Current Password"
              labelTag
              gap
            />

            <InputFields
              type="password"
              control={control}
              fieldName="newPassword"
              label="New Password"
              rules={{
                required: "New Password is required",
                minLength: {
                  value: 8,
                  message: "New Password must be at least 8 characters long",
                },
                validate: {
                  hasSpecialChar: (value) =>
                    hasSpecialChar(value) || 'Password must have at least one special character',
                  hasNumber: (value) =>
                    hasNumber(value) || 'Password must have at least one number'
                }
              }}
              error={errors?.newPassword}
              placeholder="Enter New Password"
              labelTag
              gap
            />

            <InputFields
              type="password"
              control={control}
              fieldName="newPasswordConfirm"
              label="Confirm Password"
              rules={{
                required: "Confirm Password is required",
                minLength: {
                  value: 8,
                  message:
                    "Confirm Password must be at least 8 characters long",
                },
                validate: {
                  hasSpecialChar: (value) =>
                    hasSpecialChar(value) || 'Password must have at least one special character',
                  hasNumber: (value) =>
                    hasNumber(value) || 'Password must have at least one number',
                  passwordMatched: (value) =>
                    value === newPassword || 'Password Does not match'
                }
              }}
              error={errors?.newPasswordConfirm}
              placeholder="Enter Confirm Password"
              labelTag
              gap
            />
            <Box sx={{ px: 1 }}>
              <Typography variant="h3">Password Criteria</Typography>
              <List>
                <ListItem className={getErrorsList.characterLimit === "error" && "error"}>
                  {renderIcon("characterLimit")}
                  <Typography>Between 8 to 20 characters</Typography>
                </ListItem>
                <ListItem className={getErrorsList.upperCase === "error" && "error"}>
                {renderIcon("upperCase")}
                  <Typography>Must contain one upper case letter</Typography>
                </ListItem>
                <ListItem className={getErrorsList.specialCharacter === "error" && "error"}>
                {renderIcon("specialCharacter")}
                  <Typography>Must contain one special character</Typography>
                </ListItem>
                <ListItem className={getErrorsList.oneNumber === "error" && "error"}>
                {renderIcon("oneNumber")}
                  <Typography> Must contain one number</Typography>
                </ListItem>
                <ListItem className={getErrorsList.matchPassword === "error" && "error"}>
                {renderIcon("matchPassword")}
                  <Typography> Password and confirm password should be same</Typography>
                </ListItem>
              </List>
            </Box>
          </Box>

          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button
              onClick={() => {
                handleDialogClose();
              }}
              variant="outlined"
              startIcon={<IoCloseOutline />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              startIcon={!loading && <IoCheckmarkOutline />}
              type="submit"
              loading={loading}
            >
             {loading? 'Updating..':'Update'}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Main>
  );
};

export default ChangePasswordDialog;
