import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Grid2 as Grid, Typography, Box, Chip, IconButton } from "@mui/material";
import { RiPencilLine } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";

import {
  deleteUserProfilePic,
  updateUserProfilePic,
} from "src/store/actions/auth";
import { languages, timezones } from "src/data/data";

import Card from "src/components/ui/Card";
import Caption from "../../components/common/Caption";
import Button from "../../components/Button";

import Confirm from "src/components/dialogs/Confirm";
import ChangePasswordDialog from "./ChangePasswordDialog";
import UserAvatar from "../../components/ui/UserAvatar";
import Upload from "src/components/dialogs/Upload";

import "../../../src/styles/profile.scss";
import "./ViewProfile.scss";
import { getUser } from "src/store/actions/users";
import { setUserData } from "src/store/reducers/auth-slice";

const ViewProfile = ({ isEditProfile }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const userData = useSelector((state) => state.auth.userData);
  const { company, _id: id } = userData;
  const companyId = company?.companyId || company?._id;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let padding = { padding: "50px" };

  if (isEditProfile) {
    padding = { padding: "17px" };
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const uploadProfileImage = (image) => {
    dispatch(updateUserProfilePic(image));
  };

  const deleteProfileImage = () => {
    dispatch(deleteUserProfilePic({})).then(() => setIsDeleteDialogOpen(false));
  };

  const lang = languages.find((lang) => lang.value === userData?.lang) || null;
  const timezone = timezones.find(
    (timezone) => timezone.value === userData?.timezone
  );

  useEffect(() => {
    if (id && companyId)
      dispatch(getUser({ id, companyId })).then((res) => {
        const newUserData = { ...userData, ...res.payload };
        dispatch(setUserData(newUserData));
      });
  }, [id, companyId]);

  return (
    <>
      <Confirm
        isOpen={isDeleteDialogOpen}
        handleClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        title="Are you sure?"
        subtitle="you want to delete picture?"
        handleConfirm={deleteProfileImage}
      />

      <ChangePasswordDialog
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleCloseDialog}
      />

      <Upload
        title="Change User Image"
        show={isUploadDialogOpen}
        type="image"
        uploadHandler={uploadProfileImage}
        close={() => setIsUploadDialogOpen(false)}
        uploadLimit={1}
      />

      <Card
        classes={`profile ${isEditProfile ? "edit" : "view"}`}
        cardStyle="card"
        style={padding}
      >
        <Grid container spacing={2} alignItems="center">
          {userData && (
            <>
              <Grid item size={5}>
                <Stack
                  direction="row"
                  spacing={5}
                  alignItems="center"
                  justifyContent="center"
                  // className={!isEditProfile && "dividerr"}
                >
                  <Box
                    sx={{
                      width: "34%",
                      aspectRatio: "1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <UserAvatar
                      name={userData.firstName + " " + userData.lastName}
                      image={userData.profile_pic}
                      type="profile"
                    >
                      <div className="uploadOverlay">
                        <IconButton
                          variant="contained"
                          onClick={() => setIsUploadDialogOpen(true)}
                        >
                          <RiPencilLine></RiPencilLine>
                        </IconButton>
                        {userData.profile_pic && (
                          <IconButton
                            variant="contained"
                            onClick={() => {
                              setIsDeleteDialogOpen(true);
                            }}
                          >
                            <FiTrash2></FiTrash2>
                          </IconButton>
                        )}
                      </div>
                    </UserAvatar>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="heading"
                      component="h3"
                      className="break"
                    >
                      {`${userData?.firstName || ""} ${
                        userData?.middleName ? `${userData.middleName} ` : ""
                      }${userData?.lastName || ""}`.trim()}
                    </Typography>
                    <Typography sx={{ my: 2 }}>
                      <Chip
                        label={userData.role}
                        sx={{
                          background: "rgba(14, 37, 208, 0.15)",
                          color: "#2E3A94",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      ></Chip>{" "}
                    </Typography>
                    <Caption
                      label="Company"
                      value={userData?.company?.name}
                    ></Caption>
                  </Box>
                </Stack>
              </Grid>

              {!isEditProfile && (
                <>
                  <Grid item size="auto">
                    <Box className="divider" sx={{width:'10px', height:'150px'}}></Box>
                  </Grid>
                  <Grid item size={5}>
                    <Stack direction="row" spacing={2} alignItems="top">
                      <Box sx={{ width: "50%" }}>
                        <Caption
                          label="Email"
                          value={userData?.email}
                        ></Caption>
                        <Caption
                          label="Mobile"
                          value={userData?.mobile || "no mobile"}
                        ></Caption>
                        <Caption
                          label="Language"
                          value={lang?.label || "no language"}
                        ></Caption>
                      </Box>
                      <Box>
                        <Caption
                          label="Status"
                          value={userData?.status ? "Active" : "Deactive"}
                        ></Caption>
                        <Caption
                          label="Job Title"
                          value={userData.title || "no country"}
                        ></Caption>
                        <Caption
                          label="Timezone"
                          value={timezone?.label || "no timezone"}
                        ></Caption>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item size="auto" textAlign="right">
                    <Button
                      variant="contained"
                      onClick={() => navigate(`/profile/edit/${userData?._id}`)}
                      sx={{ mb: 2, minWidth: "150px" }}
                    >
                      Edit Profile
                    </Button>
                    <br></br>
                    <Button
                      variant="contained"
                      onClick={() => setIsDialogOpen(true)}
                      color="secondary"
                      sx={{ minWidth: "150px" }}
                    >
                      Change Password
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default ViewProfile;
