import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

//Icons
import { IoAdd, IoCreate, IoCloudUploadOutline } from "react-icons/io5";

//APIs
import { fetchEmails, updateEmail } from "src/store/actions/emails";

//MUI Componenets
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DropDown from "src/components/ui/DropDown";
import Pagination from "src/components/ui/Pagination";
import EmailCard from "src/components/emails/EmailsCard";

//Dialogs
import EmailMyLibraryDialogs from "src/components/dialogs/emails/EmailMyLibrary-Dialogs.jsx";
import Permission from "src/components/Permission";
import FolderList from "src/components/folder";
import Breadcrumbs from "src/components/folder/FolderBreadcrumbs";
import MoveDialog from "src/components/folder/MoveDialog";
import { clearFolder } from "src/store/reducers/folder-slice";
import { removeEmaiFromUi } from "src/store/reducers/emails-slice";
const MyLibrary = () => {
  const dispatch = useDispatch();
  const {
    list: emails,
    count,
    loadingMsg,
  } = useSelector((state) => state.emails);

  const [searchParams] = useSearchParams();
  const folderId = searchParams.get("folderId") || null;
  const [details, setDetails] = useState({});
  const [emailId, setEmailId] = useState("");
  const [moveableItem, setMoveableItem] = useState(null);
  const [fetchEmailsLoading, setFetchEmailsLoading] = useState(false);
  //Dialogs State
  const [dialog, setDialog] = useState({});

  const limit = 12;
  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;

    const params = {};
    searchParams.forEach((value, key) => {
      if (key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;

    setFetchEmailsLoading(true)
    dispatch(fetchEmails(params)).then(()=>{
      setFetchEmailsLoading(false)
    })

    return () => {
      dispatch(clearFolder());
    };
  }, [searchParams]);

  const dropDownData = useMemo(() => {
    return [
      {
        label: "Create Folder",
        icon: <IoCreate />,
        onClick: () => {
          openDialog("", "createfolder");
        },
      },
      {
        label: "Create New",
        icon: <IoCreate />,
        onClick: () => {
          openDialog("", "createNew");
        },
        permissionPath: "emailTemplates.create.createViaTemplate",
      },
      {
        label: "Upload From",
        icon: <IoCloudUploadOutline />,
        onClick: () => {
          openDialog("", "uploadNew");
        },
        permissionPath: "emailTemplates.create.uploadZipFile",
      },
    ];
  }, []);

  const handleMoveEmail = (selectedFolder = null) => {
    dispatch(
      updateEmail({
        data: { folderId: selectedFolder },
        id: moveableItem?._id,
      })
    ).then(() => {
      setMoveableItem();
      // dispatch(getFoldersList({ parentId: folderId, type: moduleType }));
      dispatch(removeEmaiFromUi(moveableItem?._id))
    });
  };

  //email info
  const showDetails = useCallback((email) => {
    setEmailId(email._id);
    setDetails(email);
    setDialog({ info: true });
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name, email) => {
    setEmailId(id);
    setDetails(email);
    setDialog({ [name]: true });
  }, []);
  
  
  return (
    <>
      {/* Dialogs */}
      <EmailMyLibraryDialogs
        dialog={dialog}
        email={details}
        emailId={emailId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}
      <div className="headersection">
        <Breadcrumbs count={count} />

        <Permission permissionPath="emailTemplates.create">
          <DropDown
            dropText="Add New"
            tooltip="options"
            startIcon={<IoAdd />}
            items={dropDownData}
          />
        </Permission>
      </div>
      <FolderList items={emails.length} />
      {(!emails.length && !fetchEmailsLoading && !folderId) && (
        <Typography variant="h1" pl={3} pt={2}>
          No Email Found
        </Typography>
      )}

      {emails.length > 0 && (
      <Grid container spacing={1.5} >
          {emails.map((email) => {
            if (email.folderId == folderId)
              return (
                <EmailCard
                  key={email._id}
                  email={email}
                  openDialog={openDialog}
                  showDetails={showDetails}
                  setEmail={setDetails}
                  role="owner"
                  setMoveableItem={setMoveableItem}
                />
              );
            return "";
          })}
        </Grid>
      )}
      {!fetchEmailsLoading && (
      <Pagination
        count={count}
        itemsPerPage={limit}
        enableParams
        onChange={(value) => {
          if (
            !searchParams.get("search") &&
            !searchParams.get("language") &&
            !searchParams.get("country")
          ) {
            dispatch(fetchEmails(value));
          }
        }}
      />
        )}
      <MoveDialog
        show={moveableItem}
        close={() => setMoveableItem()}
        moveableItem={moveableItem}
        onSubmit={handleMoveEmail}
      />
    </>
  );
};
export default React.memo(MyLibrary);
