import React, { useEffect, useState, useMemo } from "react";
import Control from "./Control";
import { Slider, IconButton,Tooltip } from "@mui/material";
import {
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalRight,
} from "react-icons/md";
import Row from "src/components/properties/Row";
import "src/styles/styles.scss";
import "src/styles/properties.scss";
import { getPadding } from "src/utils/Utils";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";

const getParent = (element) => {
  return element.classList.contains('cs-layer') 
    ? element.parentElement 
    : element.closest('.cs-layer');
}

const getImageWidth = (element) => {
  const parent = getParent(element);
  const parentPadding = getPadding(parent, true).inline()
  const parentWidth = parent.clientWidth - parentPadding;
  return parentWidth
}

const pixelToPercentage = (element) => {
  const parent = getParent(element);
  const parentPadding = getPadding(parent, true).inline()
  const parentWidth = parent.clientWidth - parentPadding;
  const elementWidth = element.clientWidth;
  const widthInPercent = (elementWidth / parentWidth) * 100;
  if(widthInPercent % 1 === 0 ){
    return widthInPercent;
  }else{
    return widthInPercent.toFixed(1);
  }
  
};

const percentageToPixels = (element, percentage) => {
  const parent = getParent(element);
  const parentPadding = getPadding(parent, true).inline()
  const parentWidth = parent.clientWidth - parentPadding;
  const pixelValue = (parentWidth * percentage) / 100;
  if(pixelValue % 1 === 0 ){
    return pixelValue;
  }else{
    return pixelValue.toFixed(1);
  }
}

const Width = ({
  elementId,
  undoRedoChange,
  updateHistory,
  heading,
  isCollapse,
  collapseTitle,
}) => {
  const [widthInPercentage, setWidthInPercentage] = useState();
  const [widthInPixel, setWidthInPixel] = useState();
 
  const element = document.getElementById(elementId);
  const imageMaxWidth = useMemo(() => getImageWidth(element), [elementId]);
  useEffect(() => {
    const getEleWidth = element.style.width;
    if(getEleWidth){
      const isWidthInPercent = getEleWidth.includes('%');
      if(isWidthInPercent){
        const percentWidth = getEleWidth.split("%")[0];
        console.log('percentWidth', percentWidth)
        console.log('pix', percentageToPixels(element, percentWidth))
        console.log(element, element.parentElement)
        setWidthInPercentage(percentWidth);
        setWidthInPixel(percentageToPixels(element, percentWidth));
      }else{
        const pixelWidth = getEleWidth.split("px")[0]
        console.log('pixelWidth', pixelWidth)
        setWidthInPixel(pixelWidth);
        setWidthInPercentage(pixelToPercentage(element))
      }
    }else{
      clearWidth()
    }
  }, [elementId, undoRedoChange]);
  
  const clearWidth = ()=>{
    setWidthInPixel("");
    setWidthInPercentage("")
    element.style.width = "";
  }

  const handleStopTyping = useDebouncedCallback(() => {
    updateHistory()
  }, 1000);

  const percentageWidthHandler = (event) => {
    const { value } = event.target;
    // If value is 0, clear the width
    if (value === "0") {
      clearWidth();
      return;
    }

    // If value is between 1 and 100, update the state and element's style
    if (value > 0 && value <= 100) {
      element.style.width = value + "%";
      setWidthInPercentage(value);
      setWidthInPixel(percentageToPixels(element, value));
    }
    handleStopTyping()
  };
  
  const pixelWidthHandler = (event) => {
    const { value } = event.target;
    element.style.width = value + "px"; 
    if(value > 0){
      setWidthInPixel(value);
      setWidthInPercentage(pixelToPercentage(element))
    }else{
      clearWidth()
    }
    handleStopTyping()
  };
  
  const alignStart = (e) => {
    const element = document.getElementById(elementId);
    element.style.display = "block";
    element.style.margin = "0 auto 0 0";
    changeActive(e, "activeEleAlign");
  };
  const alignCenter = (e) => {
    const element = document.getElementById(elementId);
    element.style.display = "block";
    element.style.margin = "0 auto 0 auto";
    changeActive(e, "activeEleAlign");
  };
  const alignEnd = (e) => {
    const element = document.getElementById(elementId);
    element.style.display = "block";
    element.style.margin = " 0 0 0 auto";
    changeActive(e, "activeEleAlign");
  };

  // Add Component Class in the array Below If you want to make it eligible for text Alignment
  const textAlignEligible = [
    "cs-input",
    "cs-button",
    "cs-link",
    "cs-rating",
    "cs-social",
  ];

  const textAlignStart = (e) => {
    const element = document.getElementById(elementId);
    element.style.textAlign = "start";
    changeActive(e, "activeTextAlign");
  };
  const textAlignCenter = (e) => {
    const element = document.getElementById(elementId);
    element.style.textAlign = "center";
    changeActive(e, "activeTextAlign");
  };
  const textAlignEnd = (e) => {
    const element = document.getElementById(elementId);
    element.style.textAlign = "end";
    changeActive(e, "activeTextAlign");
  };

  const changeActive = (e, className) => {
    const element = document.querySelector(`.${className}`);
    if (element) {
      element.classList.remove(className);
    }
    e.currentTarget.classList.add(className);
    updateHistory();
  };

  const getMargin = () => {
    const margin = { left: false, right: false, center: false };
    if(element && element.style){
      const style = element?.style;
      if (style.marginLeft == "auto" && style.marginRight == "auto") {
        margin.center = true;
      }

      if (style.marginLeft == "auto" && style.marginRight != "auto") {
        margin.left = true;
      }

      if (style.marginRight == "auto" && style.marginLeft != "auto") {
        margin.right = true;
      }
    }
    return margin;
  };

  const getTextAlign = () => {
    const text = { left: false, right: false, center: false };
    const style = element.style;

    if (style.textAlign == "start") {
      text.right = true;
    }

    if (style.textAlign == "end") {
      text.left = true;
    }

    if (style.textAlign == "center") {
      text.center = true;
    }

    return text;
  };
  
  return (
    <>
      <Control
        heading={collapseTitle !== undefined ? collapseTitle : ""}
        isCollapse={isCollapse !== undefined ? isCollapse : false}
        divider={true}
        expanded={true}
      >
        <Row
          label={heading ? heading : "Width %"}
          element={
            <input
              type="number"
              name="left"
              className="input"
              value={widthInPercentage}
              onChange={percentageWidthHandler}
              // onBlur={()=>{
              //   updateHistory();
              // }}
              step="0.1"
              max="100"
            />
          }
        />
        <Row
          label={heading ? heading : "Width PX"}
          element={
            <input
              type="number"
              name="left"
              className="input"
              value={widthInPixel}
              onChange={pixelWidthHandler}
              onBlur={()=>{
                // console.log('element', pixelToPercentage(element))
                if(element.style.width.includes('px')){
                  element.style.width = pixelToPercentage(element) + '%'
                }
                // updateHistory();
              }}
              max={imageMaxWidth}
            />
          }
        />
        
        {/*if element has block style then alignment controll will appear*/}
        {(element && element.style.display !== 'inline-block' && widthInPercentage < 100) && (
          <Row
            label="Alignment"
            element={
              <>
              <Tooltip title="Left Align" placement="top" arrow>
                <IconButton
                  onClick={alignStart}
                  size="small"
                  color="secondary"
                  className={getMargin().right && "activeEleAlign"}
                >
                  <MdOutlineAlignHorizontalLeft  fontSize="small" />
                </IconButton>
                </Tooltip>
                <Tooltip title="Center Align" placement="top" arrow>
                <IconButton
                  onClick={alignCenter}
                  size="small"
                  color="secondary"
                  className={getMargin().center && "activeEleAlign"}
                >
                  <MdOutlineAlignHorizontalCenter  fontSize="small"  />
                </IconButton>
                </Tooltip>
                <Tooltip title="Right Align" placement="top" arrow>
                <IconButton
                  onClick={alignEnd}
                  size="small"
                  color="secondary"
                  className={getMargin().left && "activeEleAlign"}
                >
                  <MdOutlineAlignHorizontalRight  fontSize="small" />
                </IconButton>
                </Tooltip>
              </>
            }
          />
        )}

        {element && textAlignEligible.some((item) => element?.classList.contains(item)) && (
          <Row
            label="Text Alignment"
            element={
              <>
                <IconButton
                  onClick={textAlignStart}
                  size="small"
                  color="secondary"
                  className={getTextAlign().right && "activeTextAlign"}
                >
                  <MdOutlineAlignHorizontalLeft fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={textAlignCenter}
                  className={getTextAlign().center && "activeTextAlign"}
                >
                  <MdOutlineAlignHorizontalCenter fontSize="small"  />
                </IconButton>
                <IconButton
                  onClick={textAlignEnd}
                  size="small"
                  color="secondary"
                  className={getTextAlign().left && "activeTextAlign"}
                >
                  <MdOutlineAlignHorizontalRight fontSize="small"  />
                </IconButton>
              </>
            }
          />
        )}
      </Control>
    </>
  );
};

export default React.memo(Width);
