import tempIcon from "../../../assets/images/smallLogo.png";
// Default assets
const defaultImage =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/image-comp/cs-default-image.jpg";
const carouselImage1 =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-01.jpg";
const carouselImage2 =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-02.jpg";
const carouselImage3 =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/carousel-comp/cs-default-carousel-image-03.jpg";
const defaultAudio =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/audio-comp/cs-default.mp3";
const defaultVideo =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/video-comp/default.mp4";
const defaultVideoThumb = "https://createmart-seed-folder.s3.amazonaws.com/placeholders/video-comp/default.jpg";
const defaultStar =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/rating-comp/star_nofill.png";
const defaultfacebook =
  "https://createmart-seed-folder.s3.amazonaws.com/placeholders/social-comp/facebook.png";

export const csLayer = (
  id,
  width = "100",
  type,
  elements = "",
  editorType,
  defaultStyling = ""
) => {
  const tagname = editorType === "microsite" && (type === "column" || type === "block") ? "section" : "div";
  let style = `width:${width}px;border-radius:0px;transform:rotate(0deg);box-sizing:border-box;`;
  if (type == "container") {
    style = `width:${width}px;height:300px;border-radius:0px;transform:rotate(0deg);border:1px solid #f0f0f0;box-sizing:border-box;`;
  }
  
  return `<${tagname} class="cs-layer ${type}-component" id="layer-${id}" style="${style}${defaultStyling}" data-state='created'>
    ${elements}
  </${tagname}>`;
};

export const csImage = (
  componentType = "presentation",
  imageUrl = defaultImage,
  defaultStyling = ""
) => {
  return componentType == "presentation"
    ? `
          <img data-src="${imageUrl}" src="${imageUrl}" class="cs-image" style="text-align:center"  draggable="false"/>
          `
    : `<a class="link" target="_blank"><img data-src="${imageUrl}" src="${imageUrl}" class="cs-image" style="text-align:center;${defaultStyling}"  draggable="false"/></a>`;
};

export const csText = (
  defaultValue = "Use this space to add more details about your site, a customer quote, or to talk about important news.",
  defaultStyle = ""
) => {
  return `
            <div  class="cs-text"
            style="background:rgba(0,0,0,0);${defaultStyle}">${defaultValue} </div>
          `;
};

export const csContainer = () => {
  return `<div class="container" style='height:100%;'></div>`;
};

export const csPopup = (id) => {
  return `
  <div class="cs-popup" style='height:100%;' data-close-out-click="true" data-show-slide-enter="false" data-label="popup title">
  <button class="closePop" style="fill:#fff;">
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="48"
viewBox="0 0 24 24"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969
L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031
4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path></svg>
  </button>
  </div>`;
};

export const csCheckboxes = (id) => {
  const color = "#0096f3"; // optional
  return `<div class="cs-checkboxes" data-shortcode="true" data-color=${color}>
            <div data-label="Checkbox 1" data-checked="true" ></div>
            <div data-label="Checkbox 2" data-checked="false" ></div>
          </div>`;

  return `<div class="cs-checkboxes">
            <div class="checkbox-row">
              <div style="border-color : #0096ff" class="checkbox checked">
                <svg fill="#0096ff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path fill="inherit" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"/> </g> </svg>
              </div>
              <span class="label">Checkbox 1</span>
            </div>
            <div class="checkbox-row">
              <div style="border-color : #0096ff" class="checkbox">
                <svg fill="#0096ff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path fill="inherit" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"/> </g> </svg>
              </div>
              <span class="label">Checkbox 1</span>
            </div>
          </div>`;
};

export const csButton = () => {
  return `<input class="cs-button" type='button' value='Type here' data-value='Type here' style='background-color:#ddd;border-radius:6px' />`;
};
export const csLink = () => {
  return `<a href="" class="cs-link" type='button' target="_blank" data-value='Type here' style="color:#FFFFFF; text-align:center; margin:0px auto; background-color:rgb(28 139 0); display:block; border-radius:5px; text-decoration: none; padding:8px 10px;">Add button text</a>`;
};

export const csRadioContainer = (id) => {
  const color = "#0096f3"; // optional
  return `<div class="cs-radio-container" data-shortcode="true" data-color=${color}>
            <div data-label="Radio 1" data-checked="true" ></div>
            <div data-label="Radio 2"></div>
          </div>`;

  return `<div class="cs-radio-container">
            <div class="radio-row">
              <div class="radio checked">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="
                fill: #0096f3;
                ">
                  <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="inherit"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="inherit"></path>
                </svg>
              </div>
              <span class="label radioText" style="color: #000000;">Radio 1</span>
            </div>
            <div class="radio-row">
              <div class="radio">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="
                fill: #0096f3;
                ">
                  <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="inherit"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="inherit"></path>
                </svg>
              </div>
              <span class="label radioText" style="color: #000000;">Radio 2</span>
            </div>
          </div>`;
};

export const csTable = () => {
  const bg = "background:rgba(0,0,0,0);";
  const th = csInput();
  const td = csInput();
  return `
            <table
            data-shortcode="true"
    class="cs-table"
    cellpadding="0"
    cellspacing="0"
    style="border:1px solid #ddd;width:100%;padding:10px"
  >
  </table>
  `;
};
export const csFlipCard = () => {
  return `<div class="cs-flipCard" data-shortcode="true" data-front-image="${defaultImage}" data-back-image="${defaultImage}" data-flip-direction="horizontal"></div>`;
};

export const csSlider = (id) => {
  return `<div class="cs-slider" data-shortcode="true" data-trackcolor="#057EFF" data-thumbcolor="#00BFFF" data-value="20"></div>`;
  return `<div class="cs-slider">
  <div class="track" style="background: rgb(5, 126, 255);"></div>
  <div class="thumb" style="left: calc(20% - 4.8px); background: rgb(0, 191, 255);"></div>
  </div>`;
};
export const csAudio = () => {
  return `
        <div class="cs-audio" data-shortcode="true"  data-src='${defaultAudio}' data-time='0' data-play='false' data-mute='false'></div>`;
};

export const csInput = (defaultStyle = "", defaultValue = "Add Your Title Here") => {
  return `<div class="cs-input" style="padding:10px;word-break:break-all;color:#000000;font-family:Arial;font-weight:normal;font-size:16px;font-style:normal;text-transform:none;text-align: center;${defaultStyle}" data-value="${defaultValue}">${defaultValue}</div>`;
};

export const csGraph = () => {
  return `<canvas class="cs-graph" width="100%" height="100%" class="cs-graph" />`;
};

export const csCoverFlow = () => {
  return `
  <div class="cs-coverflow" data-buttons="false" data-shortcode="true">
    <div data-text="Text 1" data-image="${carouselImage1}"></div>
    <div data-text="Text 2" data-image="${carouselImage2}"></div>
    <div data-text="Text 3" data-image="${carouselImage3}"></div>
  </div>
  `;
  return `
    <div class="cs-coverflow" data-buttons="false">
      <ul class="flip-items">
          <li>
            <p class="text" style="top : 85%">Text 1</p>
            <img src="${carouselImage1}" />
          </li>
          <li>
            <p class="text" style="top : 85%">Text 2</p>
            <img src="${carouselImage2}" />
          </li>
          <li>
            <p class="text" style="top : 85%">Text 3</p>
            <img src="${carouselImage3}" />
          </li>
      </ul>
  </div>
  `;
};
export const csVideo = () => {
  return `
        <div class="cs-video"  data-shortcode="true"
        data-src='${defaultVideo}' data-time='0' data-play='false' data-mute='false' data-loop="false" data-play-slide-enter="false"
        data-poster='${defaultVideoThumb}'  data-controls='false' data-full-screen-play='false'></div>`;
};

export const csCarousel = () => {
  let buttonStyles =
    '{"color":"#ffffff","width":"16px","strokeWidth":"3px","buttonPosition":"calc(50% - 63.5px)"}'; // Optional
  return `
        <div class="cs-carousel" data-shortcode="true" data-buttons="true"  data-button-styles='${buttonStyles}'>
          <div data-heading="heading 1" data-text="Text 1" data-image="${carouselImage1}"></div>
          <div data-heading="heading 2" data-text="Text 2" data-image="${carouselImage2}"></div>
          <div data-heading="heading 3" data-text="Text 3" data-image="${carouselImage3}"></div>
        </div>
      `;
  return `
      <div class="cs-carousel" data-buttons="true">
        <ul class="flip-items">
          <li class="flipster__item">
            <img src="${carouselImage1}" />
           <div class="subtitle">
              <p class="heading">Chicago</p>
             <pre class="text">Thankyou , Chicago!</pre>
           </div>
          </li>
          <li class="flipster__item">
            <img src="${carouselImage2}" />
            <div class="subtitle">
              <p class="heading">New York</p>
              <pre class="text">We love the Big Apple!</pre>
           </div>
          </li>
          <li class="flipster__item">
            <img src="${carouselImage3}" />
            <div class="subtitle">
              <p class="heading">Los Angeles</p>
              <pre class="text">LA is always so much fun!</pre>
            </div>
          </li>
        </ul>
      </div>
  `;
};

export const csExtendedCard = () => {
  return `
        <div class="cs-extendedCard" data-shortcode="true" data-image=${defaultImage}></div>`;
};

export const csTabGroup = () => {
  return `
        <div class="cs-tabGroup" style="height:100%;" data-shortcode="true" data-active-btn-color='#ffffff' data-active-btn-backcolor='#2980b9'></div>`;
};
export const csGrid = () => {
  // return `
  // <table class="cs-grid default-height">
  // <tr><td class="cs-grid-item" style='position:relative'></td></tr>
  // </table>`;
  return `
       <table style="width : 100%" class="cs-grid default-height">
        <tbody>
        <tr>
          <td class="cs-grid-item" style='position:relative;width:50%'></td>
          <td class="cs-grid-item" style='position:relative;width:50%'></td>
        </tr>
        </tbody>
       </table>`;
};

export const csList = () => {
  return `
    <ul class="cs-list" style='list-style:circle; padding-left:40px; margin:0;'>
      <li style="list-style:inherit;"> List Item 1 </li>
      <li style="list-style:inherit;"> List Item 2 </li>
    </ul>`;
};
export const csRating = () => {
  return `
        <div class="cs-rating" style = "text-align:center">
            <img src = "${defaultStar}" />
            <img src = "${defaultStar}" />
            <img src = "${defaultStar}" />
            <img src = "${defaultStar}" />
            <img src = "${defaultStar}" />
        </div>`;
};
export const csDivider = () => {
  return `
        <div class="cs-divider" style="width:100%;border-top:1px solid gray;padding: 0;">
        </div>`;
};
export const csSocialIcons = (
  iconList = [],
  iconStyling = "",
  componentStyling = ""
) => {
  console.log(componentStyling);
  if (iconList.length > 0) {
    let snippet = "";
    iconList.forEach((data, i) => {
      snippet += `<a key=${i} data-icon-name = ${data.icon} href = "https://${
        data.href
      }.com" target = "_blank" style = "width: 32px; display: inline-block;${
        iconStyling && iconStyling
      }">
                    <img src="https://createmart-seed-folder.s3.amazonaws.com/placeholders/social-comp/${
                      data.icon
                    }.png" style="width:100%; border-radius:0%; filter:none" /> 
                  </a>`;
    });
    return `<div class="cs-social" style="width:100%;${
      componentStyling && componentStyling
    }">${snippet}</div>`;
  } else {
    return `<div class="cs-social" style="width:100%;text-align:center">  
              <a data-icon-name = "facebook" href = "https://facebook.com" target = "_blank" style = "width: 32px; display: inline-block;">
                <img src="${defaultfacebook}" style="width:100%; border-radius:0%; filter:none" />  
              </a>
            </div>`;
  }
};

export const csColumn = (columnLayout) => {
  switch (columnLayout) {
    case "layout-single":
      return `<div class="container-fliud">
                <div class="cs-row ">
                  <div class="cs-col" data-desktop-width="100" data-mobile-width="100" ></div>
                </div>
              </div>`;

    case "layout-1":
      return `<div class="container-fliud">
                  <div class="cs-row ">
                  <div class="cs-col" data-desktop-width="50" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="50" data-mobile-width="100"></div>
                </div>
              </div>`;

    case "layout-2":
      return `<div class="container-fliud">
                  <div class="cs-row " style="justify-content: center;">
                  <div class="cs-col" data-desktop-width="33.33333333" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="33.33333333" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="33.33333333" data-mobile-width="100"></div>
                </div>
              </div>`;

    case "layout-3":
      return `<div class="container-fliud">
                  <div class="cs-row " style="justify-content: center;">
                  <div class="cs-col" data-desktop-width="25" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="25" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="25" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="25" data-mobile-width="100"></div>
                </div>
              </div>`;

    case "layout-4":
      return `<div class="container-fliud">
                <div class="cs-row ">
                  <div class="cs-col" data-desktop-width="25" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="75" data-mobile-width="100"></div>
                </div>
              </div>`;

    case "layout-5":
      return `<div class="container-fliud">
                <div class="cs-row ">
                  <div class="cs-col" data-desktop-width="75" data-mobile-width="100"></div>
                  <div class="cs-col" data-desktop-width="25" data-mobile-width="100"></div>
                </div>
              </div>`;

    default:
      break;
  }
  return;
};

export const csBlock = (blockName, eType) => {
  switch (blockName) {
    case "CTA":
      return {
        html: `<div class="container-fliud">
                <div class="cs-row ">
                  <div class="cs-col col-md-6">${csLayer(
                    "",
                    "200",
                    "input",
                    csInput(
                      "text-align:start;color:rgb(255, 255, 255);",
                      "Super subject line"
                    ),
                    eType
                  )}</div>
                  <div class="cs-col col-md-6">${csInput(
                    "text-align:end;text-decoration:underline;color:rgb(255, 255, 255);",
                    "Super subject line"
                  )}</div>
                  <div class="cs-col col-md-6">
                    <div class="container-fliud">
                      <div class="cs-row ">
                        <div class="cs-col col-md-2">${csImage(
                          null,
                          tempIcon
                        )}</div>
                        <div class="cs-col col-md-10">${csInput(
                          "text-align:start;color:rgb(255, 255, 255);",
                          "Super subject line"
                        )}</div>                   
                      </div> 
                    </div>
                  </div>
                  <div class="cs-col col-md-6">${csSocialIcons(
                    [
                      { icon: "facebook", href: "https://facebook.com" },
                      { icon: "instagram", href: "https://instagram.com" },
                      { icon: "twitter", href: "https://twitter.com" },
                      { icon: "youtube", href: "https://youtube.com" },
                      { icon: "linkedin", href: "https://linkedin.com" },
                      { icon: "github", href: "https://github.com" },
                    ],
                    "margin:0px 4px;",
                    "text-align:end;"
                  )}
                  </div>   
                </div>
              </div>`,
        style: "background-color: rgba(26,34,132,255);",
      };
  }
};
