import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { IoCloseSharp, IoInformationCircleOutline } from "react-icons/io5";
import CardItem from "src/components/common/CardItem";
import {
  fetchSlideTemplates,
  createSlide,
} from "src/store/actions/presentations";
import { uiActions } from "src/store/reducers/ui-slice";
import "../../styles/add_slide_panel.scss";
import Drawer from "./Drawer";
import PermissionComp from "../Permission";
import { hasPermission } from "src/utils/Utils";

const AddSlidePanel = (props) => {
  const { open, onClose, presentationId, chapterIndex, slidesCount } = props;
  const { slideTemplates, presentation } = useSelector(
    (state) => state.presentations
  );
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();
  const slidePermission = hasPermission('presentation.maxNumberOfSlides')
  const handleClose = () => {
    onClose();
    setSelected({});
  };

  useEffect(() => {
    if (presentation?.targetDevice) {
      const targetDevice = presentation.targetDevice;
      dispatch(fetchSlideTemplates({ targetDevice }));
    }
  }, [presentation]);

 

  const createNewSlide = (e) => {
    e.preventDefault();
    if (selected._id) {
      const { name, _id, thumbnail, sourceTemplate } = selected;
      const obj = {
        name,
        templateId: _id,
        thumbnail,
        sourceTemplate,
        chapterIndex,
      };
      dispatch(createSlide({ presentationId, slide: obj }));
      handleClose();
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "Please select a slide template",
        })
      );
    }
  };

  return (
    <Drawer
      className="sliderPanel"
      boxClassName="boxSliderPanel"
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={createNewSlide}>
        <Grid container>
          <Grid item md={8}>
            <Typography variant="h2" className="header">
              Create New-E-Detailer
            </Typography>
          </Grid>
          <Grid item md={4} textAlign="end">
            {/* <IconButton className="header">
              <BsArrowLeftRight size={14} />
            </IconButton> */}
            <IconButton className="header" onClick={handleClose}>
              <IoCloseSharp size={14} />
            </IconButton>
          </Grid>
        </Grid>
        <Box className="horizontal-line"></Box>
        <Typography className="drawer-para">
          <span>Template Slides</span>
        </Typography>
        <Box className="slideTemplate">
          <Grid container rowSpacing="24px" columnSpacing="16px">
            {slideTemplates.map((slide, index) => {
              return (
                <Grid item md={4} key={index}>
                  <CardItem
                    classes={`addSlide-card ${
                      selected._id === slide?._id ?  "success-checkmark" : ""
                    }`}
                    onClick={() => setSelected(slide)}
                    image={slide.thumbnail}
                    title={slide.name}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <Grid container mt={2} alignItems="center">
                <Grid item md={7}>
                  <Box>
                  <Typography variant="p" component="p">
                    Slides {slidesCount} / {slidePermission}
                  </Typography>
                    <Typography variant="body2" component="p">
                    <IoInformationCircleOutline /> {`Maximum ${slidePermission} slides you can create`}
                  </Typography>
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <Stack flexDirection="row" justifyContent="space-around">
                    <Box>
                      <Button variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                    <Box>
                      <PermissionComp permissionPath="presentation.maxNumberOfSlides" currentValue={slidesCount}>
                        <Button variant="contained" type="submit">
                          Create
                        </Button>
                      </PermissionComp>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Drawer>
  );
};

export default AddSlidePanel;
