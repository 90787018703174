import React, { useState, useEffect } from "react";
import Control from "../Control";
import { URLValidator } from "src/services/editor.service";
import "src/styles/properties.scss";
import Row from "src/components/properties/Row";
import { Box, IconButton, Radio } from "@mui/material";
import SelectBox from "src/components/ui/formElements/SelectBox";
import ReviewDropdown from "./ReviewLink";
import Select_Box from "src/components/common/Select_Box";
import { HISTORY_ACTIONS } from "src/data/contants";
import { DeleteOutline } from "@mui/icons-material";
import { IoRefreshSharp, IoRemoveSharp } from "react-icons/io5";
import TooltipWrapper from "src/components/ui/Tooltip";
const isValidURL = (url) => {
  const urlPattern = !/^https?:\/\//i;
  return urlPattern.test(url);
};
const AddLink = (props) => {
  const { elementId, updateHistory, undoRedoChange } = props;
  const element = document.getElementById(elementId);
  let anchor = element?.closest("a");
  if (element && element.classList.contains("card-component")) {
    //if element type is card then find anchore inside element
    anchor = element.querySelector(".cs-text").parentElement;
  }

  const [link, setLink] = useState("");
  const [linkType, setLinkType] = useState("Input");

  const linkTypeChangeHandler = (e) => {
    const { value } = e.target;
    setLinkType(value);
  };

  const linkHandler = (e) => {
    const value = e.target.value;

    if (value) {
      try {
        const url = new URL(value);
        // If it's a valid URL, set it as is
        anchor.href = value;
        anchor.removeAttribute("data-link");
      } catch (error) {
        console.log("error", error);
        // If it's not a valid URL, treat it as a relative path and append it to the current location
        let fullURL = value;
        // Check if the URL starts with 'http://' or 'https://'
        if (!/^https?:\/\//i.test(fullURL)) {
          // If not, prepend 'https://www.'
          fullURL = `https://www.${fullURL}`;
        }
        anchor.href = fullURL;
        anchor.removeAttribute("data-link");
      }
    } else {
      anchor.removeAttribute("href");
      anchor.removeAttribute("data-link");
    }

    // Prevent default behavior for the anchor
    anchor.addEventListener("click", (e) => {
      e.preventDefault();
      console.log("Anchor click prevented.");
    });

    setLink(value);
  };

  const removePresentation = () => {
    if (anchor) {
      anchor.removeAttribute("href");
      anchor.removeAttribute("data-link");
      setLink('');
      setLinkType("Input");
      updateHistoryNew();
    }
  };

  const updateHistoryNew = () => {
    //its will make sure that link is changed and then update it into history on blur input,
    //if we dont check, then its will push updateHistory without changing the link on blur
    if (!anchor?.getAttribute("data-link")) {
      updateHistory();
      anchor?.setAttribute("data-link", "updated");
    }
  };

  useEffect(() => {
    if (anchor) {
      const href = anchor.getAttribute("href");
      if (href && (href !== "#" || href !== "")) {
        setLink(href);
        if (href.includes("preview=[")) {
          setLinkType("Presentation");
        } else {
          setLinkType("Input");
        }
      }
    } else {
      setLinkType("Input");
      setLink("");
      if (element && !element.closest("a")) {
        const a = document.createElement("a");
        a.target = "_blank";
        a.onclick = (e) => e.preventDefault();
        element.parentElement.append(a);
        a.append(element);
        anchor = a;
      }
    }
    anchor.removeAttribute("data-link");
  }, [elementId, undoRedoChange]);

  const links = [
    {
      label: "Input",
      jsx: (
        <input
          type="url"
          placeholder="Enter URL"
          className="input fullwidth-input"
          name="link"
          value={link}
          onChange={linkHandler}
          onBlur={updateHistoryNew}
        />
      ),
    },
    {
      label: "Presentation",
      jsx: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {(anchor?.href || link) && (
          <TooltipWrapper title="Remove presentation">
            <IconButton size="small" onClick={removePresentation}>
              <IoRefreshSharp fontSize="inherit" />
            </IconButton>
          </TooltipWrapper>
          )}
          <ReviewDropdown
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
            anchor={anchor}
          />
        </Box>
      ),
    },
  ];

  const linkOptions = links.map(({ label }) => ({ label, value: label }));
  const renderItem = links.find(({ label }) => label === linkType)?.jsx;

  return (
    <Row
      label={
        <Select_Box
          onChange={linkTypeChangeHandler}
          value={linkType}
          options={linkOptions}
        />
      }
      leftsx={5}
      rightsx={7}
      element={renderItem}
    />
  );
};

export default AddLink;
