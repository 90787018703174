import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import Control from "./Control";
import {
  convertRgbAToHexAplha,
  isActiveComponent,
} from "src/services/editor.service";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import { HISTORY_ACTIONS } from "src/data/contants";
import { countKeywordOccurrences, removePx } from "src/utils/Utils";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";


const getBoxShadow = (boxShadow) => {
  const returnShadow = { inset: null, outset: null };
  let inset = null, outset = null;
  if(boxShadow && typeof(boxShadow) === 'string'){
    if(countKeywordOccurrences(boxShadow, 'rgb') > 1){
      inset = boxShadow.slice(boxShadow.lastIndexOf('rgb'), boxShadow.length) 
      outset = boxShadow.slice(0, boxShadow.indexOf('px,')+2)
      
    }else{
      if(boxShadow.includes('inset')){
          inset = boxShadow
      }else{
          outset = boxShadow
      }
    }
    
    if(outset){
      const outsetColor = `rgb(${outset.split(")")[0].split("(")[1]})`;
      const outsetHorizontal = removePx(outset.split(" ")[3]);
      const outsetVertical = removePx(outset.split(" ")[4]);
      const outsetBlur = removePx(outset.split(" ")[5]);
      const outsetHexColor = convertRgbAToHexAplha(outsetColor).hex;
      returnShadow.outset = { color: outsetColor, x: outsetHorizontal, y: outsetVertical, blur: outsetBlur, hexColor: outsetHexColor }
    }

    if (inset) {
      const insetColor = `rgb(${inset.split(")")[0].split("(")[1]})`;
      const insetHorizontal = removePx(inset.split(" ")[3]);
      const insetVertical = removePx(inset.split(" ")[4]);
      const insetBlur = removePx(inset.split(" ")[5]);
      const insetHexColor = convertRgbAToHexAplha(insetColor).hex;
      returnShadow.inset = { color: insetColor, x: insetHorizontal, y: insetVertical, blur: insetBlur, hexColor: insetHexColor }
    }
  }
  return returnShadow;
}

const BoxShadow = ({
  elementId,
  updateHistory: propsUpdHistory,
  undoRedoChange,
}) => {
  const element = document.getElementById(elementId);
  const boxShadow = element?.style.boxShadow;
  const { inset, outset } = getBoxShadow(boxShadow);
  const [propertiesCheckbox, setPropertiesCheckbox] = useState({
    outset: outset? true: false,
    inset: inset? true: false,
  });

  const [outsetProperties, setOutsetProperties] = useState({
    horizontal: 0,
    vertical: 0,
    blur: 15,
    color: "#000000",
  });

  const [insetProperties, setInsetProperties] = useState({
    horizontal: 0,
    vertical: 0,
    blur: 15,
    color: "#000000",
  });

  const updateHistory = ({
    action = HISTORY_ACTIONS.STYLED_ACTIONS.common,
    ...rest
  } = {}) => {
    propsUpdHistory({ action, ...rest });
  };

  useEffect(() => {
    const boxShadow = element.style.boxShadow;
    if (boxShadow) {
      const { inset, outset } = getBoxShadow(boxShadow);
      if (inset && outset) {
        setOutsetProperties({
          horizontal: outset.x,
          vertical: outset.y,
          blur: outset.blur,
          color: outset.hexColor,
        });
        setInsetProperties({
          horizontal: inset.x,
          vertical: inset.y,
          blur: inset.blur,
          color: inset.hexColor,
        });
        setPropertiesCheckbox({ outset: true, inset: true });
      } else if (inset && !outset) {
        setInsetProperties({
          horizontal: inset.x,
          vertical: inset.y,
          blur: inset.blur,
          color: inset.hexColor,
        });
        setPropertiesCheckbox({ outset: false, inset: true });
      } else if (outset && !inset) {
        setOutsetProperties({
          horizontal: outset.x,
          vertical: outset.y,
          blur: outset.blur,
          color: outset.hexColor,
        });
        setPropertiesCheckbox({ outset: true, inset: false });
      }
    } else {
      setPropertiesCheckbox({ outset: false, inset: false });
    }
  }, [elementId, undoRedoChange]);

  useEffect(() => {
    if (
      propertiesCheckbox.outset === true &&
      propertiesCheckbox.inset === true
    ) {
      element.style.boxShadow = `${outsetProperties.horizontal}px ${outsetProperties.vertical}px ${outsetProperties.blur}px ${outsetProperties.color}, inset ${insetProperties.horizontal}px ${insetProperties.vertical}px ${insetProperties.blur}px ${insetProperties.color}`;
    } else if (propertiesCheckbox.outset === true) {
      element.style.boxShadow = `${outsetProperties.horizontal}px ${outsetProperties.vertical}px ${outsetProperties.blur}px ${outsetProperties.color}`;
    } else if (propertiesCheckbox.inset === true) {
      element.style.boxShadow = `inset ${insetProperties.horizontal}px ${insetProperties.vertical}px ${insetProperties.blur}px ${insetProperties.color}`;
    }
  }, [outsetProperties, insetProperties]);

  useEffect(() => {
    if (propertiesCheckbox.outset === false && propertiesCheckbox.inset === false) {
      element.style.boxShadow = ''
    }
  }, [propertiesCheckbox.inset, propertiesCheckbox.outset]);

  // let timeout;
  const handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;
    if (name === "outsetCheckbox") {
      setPropertiesCheckbox((prev) => ({
        ...prev,
        outset: !propertiesCheckbox.outset,
      }));
    }
    if (name.includes("outset")) {
      const outsetIndex = name.indexOf("outset");
      const result = name.substring(outsetIndex + "outset".length);
      setOutsetProperties((prev) => ({
        ...prev,
        [result.toLowerCase()]: value,
      }));
    }

    if (name === "insetCheckbox") {
      setPropertiesCheckbox((prev) => ({
        ...prev,
        inset: !propertiesCheckbox.inset,
      }));
    }

    if (name.includes("inset")) {
      const insetIndex = name.indexOf("inset");
      const result = name.substring(insetIndex + "inset".length);
      setInsetProperties((prev) => ({
        ...prev,
        [result.toLowerCase()]: value,
      }));
    }

    //push History
    if (name === "outsetColor" || name === "insetColor" || name === "outsetCheckbox" || name === "insetCheckbox") {
      updateHistory();
    }

    if (name !== "outsetCheckbox" && name !== "insetCheckbox") {
      handleStopTyping()
    }
  };
  
  const handleStopTyping = useDebouncedCallback(() => {
    updateHistory({
      id: elementId,
    });
  }, 1000);

  const checkActiveImageComponent = isActiveComponent("image-component");
  const checkActiveAudioComponent = isActiveComponent("audio-component");
  const checkActiveVedioComponent = isActiveComponent("video-component");

  // useEffect(() => {
  //   console.log("propertiesCheckbox ", propertiesCheckbox);
  // }, [propertiesCheckbox.inset, propertiesCheckbox.outset]);

  return (
    <Control
      isCollapse={true}
      heading="Box Shadow"
      headingVariant="h2"
      divider={false}
      className="boxShadow"
      expanded={inset || outset}
    >
      <Row
        label="Outset"
        component="h3"
        element={
          <Check_Box
            size="medium"
            color="secondary"
            name="outsetCheckbox"
            checked={propertiesCheckbox.outset}
            onClick={handleChange}
          />
        }
      />

      <Row
        label="Position-X"
        element={
          <input
            type="number"
            className="input"
            name="outsetHorizontal"
            value={outsetProperties.horizontal}
            onChange={handleChange}
            disabled={!propertiesCheckbox.outset}
          />
        }
      />

      <Row
        label="Position-Y"
        element={
          <input
            type="number"
            className="input"
            name="outsetVertical"
            value={outsetProperties.vertical}
            onChange={handleChange}
            disabled={!propertiesCheckbox.outset}
          />
        }
      />

      <Row
        label="Blur"
        element={
          <input
            type="number"
            className="input"
            name="outsetBlur"
            value={outsetProperties.blur}
            onChange={handleChange}
            disabled={!propertiesCheckbox.outset}
          />
        }
      />

      <Row
        label="Color"
        element={
          <input
            type="color"
            className="color"
            name="outsetColor"
            value={
              propertiesCheckbox.outset ? outsetProperties.color : "#71716F"
            }
            onChange={handleChange}
            disabled={!propertiesCheckbox.outset}
          />
        }
      />

      {!checkActiveImageComponent &&
        !checkActiveAudioComponent &&
        !checkActiveVedioComponent && (
          <>
            <Row
              label="Inset"
              component="h3"
              element={
                <Check_Box
                  size="medium"
                  color="secondary"
                  name="insetCheckbox"
                  checked={propertiesCheckbox.inset}
                  onClick={handleChange}
                />
              }
            />
            <Row
              label="Position-X"
              element={
                <input
                  type="number"
                  className="input"
                  name="insetHorizontal"
                  value={insetProperties.horizontal}
                  onChange={handleChange}
                  disabled={!propertiesCheckbox.inset}
                />
              }
            />
            <Row
              label="Position-Y"
              element={
                <input
                  type="number"
                  className="input"
                  name="insetVertical"
                  value={insetProperties.vertical}
                  onChange={handleChange}
                  disabled={!propertiesCheckbox.inset}
                />
              }
            />
            <Row
              label="Blur"
              element={
                <input
                  type="number"
                  className="input"
                  name="insetBlur"
                  value={insetProperties.blur}
                  onChange={handleChange}
                  disabled={!propertiesCheckbox.inset}
                />
              }
            />
            <Row
              label="Color"
              element={
                <input
                  type="color"
                  className="color"
                  name="insetColor"
                  value={
                    propertiesCheckbox.inset ? insetProperties.color : "#71716F"
                  }
                  onChange={handleChange}
                  disabled={!propertiesCheckbox.inset}
                />
              }
            />
          </>
        )}
    </Control>
  );
};

export default React.memo(BoxShadow);
