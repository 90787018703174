import React, { useState, useEffect, useRef } from "react";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

import { Button, Tooltip, Typography } from "@mui/material";
import { BsLink45Deg } from "react-icons/bs";
import "src/styles/unitboxes/style.scss";
import AutoCheckBox from "./AutoCheckBox";
import UnitCheckBox from "./UnitChecbox";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";
import Info from "src/components/ui/Info";
const UnitBoxes = (props) => {
  const {
    selector,
    property,
    heading,
    updateHistory,
    undoRedoChange,
    elementId,
  } = props;

  const [magnet, setMagnet] = useState(false);
  const [unit, setUnit] = useState("px");
  const [focusEle, setFocusEle] = useState("");
  const [unitValues, setUnitValues] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useEffect(() => {
    if (selector) {
      const element = document.querySelector(selector);

      if ($(selector)[0]?.style[property].toString().includes("%")) {
        setUnit("%");
      } else {
        setUnit("px");
      }

      getProperties(property);
    }
  }, [elementId, selector, undoRedoChange]);

  const getAndSetMax = () => {
    setMagnet(!magnet);
    const maxValue = +Object.entries(unitValues).sort(
      (x, y) => y[1] - x[1]
    )[0][1];
    setUnitValues({
      top: maxValue,
      right: maxValue,
      bottom: maxValue,
      left: maxValue,
    });
  };

  // Magnet functionality
  const magnetHandler = () => {
    if (magnet) {
      updateStyleProperty(property, unitValues);
    }
  };

  const unitValueChangeHandler = (value, name) => {
    if (magnet) {
      const unitValue = {
        top: value,
        right: value,
        bottom: value,
        left: value,
      };
      setUnitValues(unitValue);
      updateStyleProperty(property, unitValue);
    } else {
      setUnitValues((pre) => {
        const unitValue = { ...pre, [name]: value };
        updateStyleProperty(property, unitValue);
        return unitValue;
      });
    }

    updateUnitHistory();
  };

  const updateUnitHistory = useDebouncedCallback(() => {
    updateHistory({
      id: elementId,
    });
  }, 1000);

  const updateStyleProperty = (property, unitValues, updatedUnit) => {
    // Getting % OR PX OR ("" for auto)
    const getValue = (v) => {
      const unitValue = v === "auto" ? "" : updatedUnit || unit;
      return `${v + unitValue} `;
    };

    // genertating css style string like 0 0 auto 1px
    const style = Object.entries(unitValues)
      .map(([_, value]) => getValue(value))
      .join("");

    // Applying Generated CSS to Element
    $(selector).css(property, style);
    //on unit change check if any values here then update history 
    if(unitValues.top || unitValues.bottom || unitValues.left || unitValues.right){
      updateUnitHistory();
    }
  };

  const getMarginOrPaddingProperty = (element, property, direction) => {
    const styledProperty =
      element?.style[`${property}-${direction}`].toString();
    return styledProperty === "auto"
      ? styledProperty
      : parseInt(styledProperty);
  };

  const getProperties = (property) => {
    let top, right, bottom, left;
    const element = $(selector).get(0);

    if (property == "padding" || property == "margin") {
      top = getMarginOrPaddingProperty(element, property, "top");
      right = getMarginOrPaddingProperty(element, property, "right");
      bottom = getMarginOrPaddingProperty(element, property, "bottom");
      left = getMarginOrPaddingProperty(element, property, "left");
    } else if (property == "border-radius") {
      top = parseInt(element.style[`border-top-left-radius`]);
      right = parseInt(element.style[`border-top-right-radius`]);
      bottom = parseInt(element.style[`border-bottom-right-radius`]);
      left = parseInt(element.style[`border-bottom-left-radius`]);
    }
    setUnitValues((pre) => ({
      ...pre,
      top: top || 0,
      right: right || 0,
      bottom: bottom || 0,
      left: left || 0,
    }));
  };

  const unitHandler = (_, text) => {
    const unitInLowercase = text.toLowerCase();
    updateStyleProperty(property, unitValues, unitInLowercase);
    setUnit(unitInLowercase);
  };

  const autoMarignHandler = (e, direction) => {
    const checked = e.target.checked;
    const value = checked ? "auto" : "0";
    unitValueChangeHandler(value, direction);
    updateHistory();
  };

  const getUnitValue = (value) => {
    return isNaN(value) ? "0" : value;
  };

  const getCheckedForAutoProperty = (direction) => {
    return unitValues[direction] === "auto";
  };

  const getMagnetDisabled = () => {
    const isAllEqual = (arr = []) => {
      return arr.every((e) => !isNaN(arr[0]) === !isNaN(e));
    };
    const propertyValues = Object.entries(unitValues).map((e) => e[1]);
    return !isAllEqual(propertyValues);
  };
  const isMagnetDisabled = getMagnetDisabled();

  return (
    <div className="unitBox row-property">
      {/* {property === 'margin' && <Info>Margin right only work if element direction from right to left</Info>} */}
      <div className="header">
        <Typography component="div">{heading && heading}</Typography>
      </div>
      <div className="boxRow">
        <div className="box">
          {property === "margin" && (
            <AutoCheckBox
              checked={getCheckedForAutoProperty("top")}
              onChange={(e) => autoMarignHandler(e, "top")}
            />
          )}
          <input
            type="number"
            min="0"
            value={getUnitValue(unitValues.top)}
            onChange={(e) => {
              unitValueChangeHandler(e.target.value, "top");
            }}
            onInputCapture={(e) => {
              unitValueChangeHandler(e.target.value, "top");
            }}
            // onBlur={updateUnitHistory}
            onFocus={() => {
              setFocusEle("top");
            }}
            onBlur={() => {
              setFocusEle("");
            }}
          />
          <label>Top</label>
        </div>
        <div className="box">
          {property === "margin" && (
            <AutoCheckBox
              type="checkbox"
              checked={getCheckedForAutoProperty("right")}
              onChange={(e) => autoMarignHandler(e, "right")}
            />
          )}
          <input
            type="number"
            min="0"
            value={getUnitValue(unitValues.right)}
            onChange={(e) => {
              unitValueChangeHandler(e.target.value, "right");
            }}
            onInputCapture={(e) => {
              unitValueChangeHandler(e.target.value, "right");
            }}
            onFocus={() => {
              setFocusEle("right");
            }}
            onBlur={() => {
              setFocusEle("right");
            }}
          />
          <label>Right</label>
        </div>
        <div className="box">
          {property === "margin" && (
            <AutoCheckBox
              type="checkbox"
              checked={getCheckedForAutoProperty("bottom")}
              onChange={(e) => autoMarignHandler(e, "bottom")}
            />
          )}
          <input
            type="number"
            min="0"
            value={getUnitValue(unitValues.bottom)}
            onChange={(e) => {
              unitValueChangeHandler(e.target.value, "bottom");
            }}
            onInputCapture={(e) => {
              unitValueChangeHandler(e.target.value, "bottom");
            }}
            onFocus={() => {
              setFocusEle("bottom");
            }}
            onBlur={() => {
              setFocusEle("bottom");
            }}
          />
          <label>Bottom</label>
        </div>
        <div className="box">
          {property === "margin" && (
            <AutoCheckBox
              type="checkbox"
              checked={getCheckedForAutoProperty("left")}
              onChange={(e) => autoMarignHandler(e, "left")}
            />
          )}
          <input
            type="number"
            min="0"
            value={getUnitValue(unitValues.left)}
            onChange={(e) => {
              unitValueChangeHandler(e.target.value, "left");
            }}
            onInputCapture={(e) => {
              unitValueChangeHandler(e.target.value, "left");
            }}
            onFocus={() => {
              setFocusEle("left");
            }}
            onBlur={() => {
              setFocusEle("left");
            }}
          />
          <label>Left</label>
        </div>
        <div className="box magnetBox">
          <div className="units">
            <UnitCheckBox
              items={["PX", "%"]}
              onChange={unitHandler}
              getActive={(textUnit) => {
                return textUnit.toLowerCase() === unit;
              }}
            />
          </div>
          {isMagnetDisabled ? (
            <Tooltip title="Magnet not works for auto value" arrow>
              <button disabled>
                <BsLink45Deg />
              </button>
            </Tooltip>
          ) : (
            <button
              className={magnet ? "active" : "deactive"}
              onClick={magnetHandler}
              onClickCapture={getAndSetMax}
            >
              <BsLink45Deg />
            </button>
          )}
          <div>&nbsp;</div>
        </div>
      </div>
      <br />
      {focusEle === "right" && property === "margin" && (
        <Info type="note" size="small">
          Margin right only works if element flow from right to left, to test
          margin right,{" "}
          <strong>You need to set element alignment to right</strong>
        </Info>
      )}
    </div>
  );
};
UnitBoxes.defaultProps = {
  selector: `.cs-layer.active`,
  property: "padding",
};

export default UnitBoxes;
