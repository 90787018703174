import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import Control from "./Control";
import GradientBackground from "./background/GradientBackground";
import ImageBackground from "./background/ImageBackground";
import ColorBackground from "./background/ColorBackground";
import { IoInformationCircleSharp } from "react-icons/io5";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import { HISTORY_ACTIONS } from "src/data/contants";
import {
  inlineStyleToObject,
  isEditor,
  masterTemplateUi,
} from "src/services/editor.service";
import { useSelector } from "react-redux";

const Background = (props) => {
  // const { elementId, updateHistory, selector } = props;
  const {
    elementId,
    updateHistory: propsUpdateHistory,
    selector,
    undoRedoChange,
  } = props;

  // const updateHistory = () => {
  //   propsUpdateHistory({ id:elementId, action: HISTORY_ACTIONS.STYLED_ACTIONS.common });
  // };
  const updateHistory = ({
    action = HISTORY_ACTIONS.STYLED_ACTIONS.common,
    ...rest
  } = {}) => {
    propsUpdateHistory({ action, ...rest });
  };

  const [bgColorCheckbox, setbgColorCheckbox] = useState({
    color: false,
    opacity: false,
  });
  const [bgGradientCheckbox, setbgGradientCheckbox] = useState(false);
  const [bgImageCheckbox, setbgImageCheckbox] = useState(false);
  const [isBgImageExist, setIsBgImageExist] = useState(false);
  let element;

  if (selector) {
    element = document
      .getElementById(elementId)
      .querySelectorAll(`${selector}`);
  } else {
    element = [document.getElementById(elementId)];
  }
 
  if(!element){
    return null;
  }

  let isDropzoneActive = element?.[0]?.getAttribute("id") === "dropzone";
  let isMasterTemplateStyle = !!element?.[0]?.getAttribute("data-template-style");
  const { editMasterTemplateOnSlide } = useSelector((state) => state.editor);
  
  useEffect(() => {
    //background Color
    const bgColor = getComputedStyle(element[0]).backgroundColor;
    const isTemplateColorOverride = masterTemplateUi.isPropertySame('backgroundColor');
    const bg = getComputedStyle(element[0]).background;
    const isTemplateBgOverride = masterTemplateUi.isPropertySame('background');
    const bgImage = getComputedStyle(element[0]).backgroundImage;
    const isTemplateBgImageOverride = masterTemplateUi.isPropertySame('backgroundImage');


    if(isMasterTemplateStyle && !editMasterTemplateOnSlide && isTemplateColorOverride){
      setbgColorCheckbox({ color: false, opacity: false });
    }else{
      if (bgColor) {
        if (bgColor === "rgba(0, 0, 0, 0)") {
          setbgColorCheckbox({ color: false, opacity: false });
        } else {
          if (bgColor.includes("rgb(")) {
            setbgColorCheckbox({ color: true, opacity: false });
          } else if (bgColor.includes("rgba(")) {
            setbgColorCheckbox({ color: true, opacity: true });
          } else {
            setbgColorCheckbox({ color: false, opacity: false });
          }
        }
      } else {
        setbgColorCheckbox({ color: false, opacity: false });
      }
    }

    //gradient color
    if(isMasterTemplateStyle && !editMasterTemplateOnSlide && isTemplateBgOverride){
      setbgGradientCheckbox(false);
    }else{
      if (bg) {
        if (bg.includes("linear-gradient") || bg.includes("radial-gradient")) {
          setbgGradientCheckbox(true);
        } else {
          setbgGradientCheckbox(false);
        }
      } else {
        setbgGradientCheckbox(false);
      }
    }

    //background Image
    if(isMasterTemplateStyle && !editMasterTemplateOnSlide && isTemplateBgImageOverride){
      setbgImageCheckbox(false);
    }else{
      if (bgImage) {
        if (bgImage.includes("canvas.cfd0ba05.png")) {
          setbgImageCheckbox(false);
        } else if (bgImage.includes("url")) {
          setbgImageCheckbox(true);
          setIsBgImageExist(true)
        } else {
          setbgImageCheckbox(false);
        }
      } else {
        setbgImageCheckbox(false);
      }
    }
    
  }, [elementId, editMasterTemplateOnSlide, undoRedoChange]);

  const inputColor = (e) => {
    setbgColorCheckbox((prev) => ({ ...prev, color: !bgColorCheckbox.color }));
    if (e.target.checked === false) {
      element.forEach((elem) => (elem.style.backgroundColor = ""));
      setbgColorCheckbox({ color: false, opacity: false });

      if (isDropzoneActive) {
        const dropzoneEle = element[0];
        let dropzoneBgImage = getComputedStyle(dropzoneEle).backgroundImage;
        if (dropzoneBgImage === "none") {
          element.forEach((elem) => (elem.style.backgroundImage = ""));
        }
        // if color input is uncheck then check dropzone has template style
        masterTemplateUi.revertProperty()
      }
      //if color checkbox uncheck then update history call to enable save button
      updateHistory();
    }else{
      if (isDropzoneActive && isMasterTemplateStyle) {
        const dropzoneEle = element[0];
        dropzoneEle.style.background = ""
      }
    }
  };

  const inputGradient = (e) => {
    setbgGradientCheckbox(!bgGradientCheckbox);
    if (bgGradientCheckbox) {
      const bgGradient = element[0].style.background;
      if (bgGradient.includes("linear") || bgGradient.includes("radial")) {
        element.forEach((elem) => {
          elem.style.background = "";
        });
      }
    }

    if (!e.target.checked) {
      if (isDropzoneActive) {
        masterTemplateUi.revertProperty()
      }
       //if gradient background checkbox uncheck then update history call to enable save button
      updateHistory();
    }
   
  };

  const inputImage = (e) => {
    setbgImageCheckbox(!bgImageCheckbox);
    if (!e.target.checked) {
      const bgImage = getComputedStyle(element[0]).backgroundImage;
      if (bgImage.includes("url")) {
        element.forEach((elem) => {
          elem.style.backgroundImage = "";
          elem.style.backgroundSize = "";
          elem.style.backgroundRepeat = "";
          elem.style.backgroundPosition = "";
          elem.style.backgroundAttachment = "";
          elem.style.backgroundBlendMode = "";
        });
      }
      
      if (isDropzoneActive) {
        masterTemplateUi.revertProperty()
      }

      //if image checkbox uncheck then update history call to enable save button
      updateHistory();
    }
  };
  return (
    <Control
      heading={"Background"}
      className="bgControl"
      headingSize={"h1"}
      divider={false}
      isCollapse={true}
    >
      {bgColorCheckbox.color === true &&
      bgColorCheckbox.opacity === false &&
      bgImageCheckbox === false ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Color is active then Background Gradient is disable
        </p>
      ) : null}
      {bgColorCheckbox.color === true && bgColorCheckbox.opacity === true ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Color and Opacity is active then Background Gradient and Image is
          disable
        </p>
      ) : null}
      {bgGradientCheckbox ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Gradient is active then Background Color and Image is disable
        </p>
      ) : null}
      {bgImageCheckbox ? (
        <p className="warningAlert">
          <IoInformationCircleSharp size={12} color="orange" /> If Background
          Image is active then Background Gradient is disable
        </p>
      ) : null}


      
      <div className="colorBackground">
        <Row
          label="Color"
          component="h3"
          element={
            <Check_Box
              name="animationCheckbox"
              color="secondary"
              size="medium"
              disabled={bgGradientCheckbox}
              checked={bgColorCheckbox.color}
              onClick={inputColor}
            />
          }
        />
        {bgColorCheckbox.color ? (
          <ColorBackground
            selector={selector}
            elementId={elementId}
            bgColorCheckbox={bgColorCheckbox}
            setbgColorCheckbox={setbgColorCheckbox}
            setbgImageCheckbox={setbgImageCheckbox}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        ) : null}
      </div>
      
      {!isEditor.email() && (  
      <div className="gradientBackground">
        <Row
          label="Gradient"
          component="h3"
          element={
            <Check_Box
              name="animationCheckbox"
              color="secondary"
              size="medium"
              disabled={bgColorCheckbox.color || bgImageCheckbox}
              checked={bgGradientCheckbox}
              onClick={inputGradient}
            />
          }
        />
        {bgGradientCheckbox ? (
          <GradientBackground
            elementId={elementId}
            selector={selector}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        ) : null}
      </div>
      )}

      {!isEditor.email() && (
      <div className="ImageBackground">
        <Row
          label="Image"
          component="h3"
          element={
            <Check_Box
              name="animationCheckbox"
              color="secondary"
              size="medium"
              disabled={bgColorCheckbox.opacity || bgGradientCheckbox}
              checked={bgImageCheckbox}
              onClick={inputImage}
            />
          }
        />
        {bgImageCheckbox ? (
          <ImageBackground
            selector={selector}
            elementId={elementId}
            bgColorCheckbox={bgColorCheckbox}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
            bgImageCheckbox={bgImageCheckbox}
            isBgImageExist={isBgImageExist}
          />
        ) : null}
      </div>
      )}
    </Control>
  );
};

export default Background;
