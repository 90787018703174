import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-rotatable";
import Card from "../../ui/Card";
import TopBar from "./topBar/TopBar";
import Leftbar from "./leftBar/LeftBar";
import Rightbar from "../righbar/Rightbar";
import RighatbarProperties from "./RighatbarProperties";

import { cleanDropzone, keybaordHandler, masterTemplateUi, takeScreenShot } from "src/services/editor.service";
import { editorActions } from "src/store/reducers/editor-slice";
import { useLocation, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { clearPresentationData } from "src/store/reducers/presentations-slice";

import { usePrompt } from "src/hooks/block_Navigation";
import Confirm from "../../dialogs/Confirm";
import { renderEditableElements, renderSlide } from "./renderPresentation";
import { getDimenssions, scaleDropzone } from "../editorLogics/components";
import "src/styles/editor.scss";
import "src/styles/components.scss";
import { getRandomId } from "src/services/utils";
import { useEditorHistory } from "src/hooks/editor/history";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";
import DeleteLayerDialogs from "../dialogs/deleteLayer/DeleteLayerDialogs";
import { uiActions } from "src/store/reducers/ui-slice";
const Editor = (props) => {
  const {
    editorHtmlData,
    onSave = () => {},
    dimenssions,
    defaultZoom,
    type,
    isLoading,
    title,
    masterTemplate,
    isMasterTemplatePage,
    isMasterSave,
    editMasterTemplateOnSlide
  } = props;
  
  const [error, setError] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState({
    open: false,
    type: "alert",
  });
  const mainWrapperRef = useRef();
  const dropzoneRef = useRef();
  const saveDataRef = useRef();
  const callBackRef = useRef();
  const dispatch = useDispatch();
  const { updateHistory, disabledButtons } = useEditorHistory();
  const { activeElement, deActiveElement, dispatchEditorActions } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();
  const activeEdtiableID = useSelector((state) => state?.editor?.activeEditable?.elementId)
  const [saveLoading, setSaveLoading] = useState(false);
  // To clear editor's data
  const clearData = () => {
    dispatch(editorActions.clearEditorData());
    dispatch(clearPresentationData());
  };
  const { id: presentationId, slideId } = useParams();

  //zoom code
  const [zoom, setZoom] = useState("1");
  const [scaledToFit, setScaledToFit] = useState(false);
  const { targetDevice } = useSelector(
    (state) => state.presentations.presentation
  );
  const isAnyChange = !disabledButtons.isChangedSaved;
  const deviceDimensions = getDimenssions(props.component, targetDevice);
  const location = useLocation();
  const isPageParam = location.search.includes('page');

  useEffect(() => {
    // To clear redux data on unrender
    return () => {
      console.log('editor unmount')
      clearData();
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  // <------BLOCKING NAVIGATION START------>
  const isChooseAssetDialog = document.querySelector('.chooseAsset')
  usePrompt(
    "Editor Have Some Unsaved Changes, Changes will be Lost \nDo you want to Exit ?",
    isAnyChange && !isChooseAssetDialog && !isPageParam,
    (goBackFunc) => {
      if (goBackFunc) {
        toggleConfirmationDialog(true, "confirm");
        callBackRef.current = goBackFunc;
      } 
      // else {
      //   toggleConfirmationDialog(true, "alert");
      // }
    }
  ); 
  // since react-router-dom has not stable release (Use Prompt is'nt available) -- Using custom hook

  const beforeUnload = useCallback((event) => {
    event.preventDefault(); // for Firefox
    event.returnValue = ""; // for Chrome
    return "";
  }, []);

  useEffect(() => {
    if (isAnyChange) {
      window.addEventListener("beforeunload", beforeUnload);
    } else {
      window.removeEventListener("beforeunload", beforeUnload);
    }
  }, [isAnyChange]);
  // <------BLOCKING NAVIGATION END------>

  const handleZoomChange = (value) => {
    if (value == "scaleToFit") {
      handleScaleToFit();
    } else {
      const minZoom = 1;
      const maxZoom = 250;
      if (value < minZoom) {
        value = minZoom;
      } else if (value > maxZoom) {
        value = maxZoom;
      }
      const zoom = value / 100;
      document.documentElement.style.setProperty("--zoom", zoom);

      scaleDropzone(
        document.getElementById("mainWrapper").parentElement,
        document.getElementById("dropzone"),
        zoom
      );
      setZoom(+value);
      setScaledToFit(false);
    }
  };

  const handleScaleToFit = () => {
    scaleDropzone(
      document.getElementById("mainWrapper").parentElement,
      document.getElementById("dropzone"),
      "scaleToFit",
      (scale) => {
        let zoom = Math.round(scale * 100);
        document.documentElement.style.setProperty("--zoom", scale);
        setZoom(+zoom);
      }
    );
    setScaledToFit(true);
  };

  // IMPORTING
  useEffect(() => {
    const dropzone = dropzoneRef.current;
    const wrapper = mainWrapperRef.current;
    let cleanUp;
    // setting Dropzone's dimensions
    if (deviceDimensions) {
      dropzone.style.width = deviceDimensions.width;
      dropzone.style.height = deviceDimensions.height;
    }
    // console.log('editorHtmlData', editorHtmlData)
    try {
      cleanUp = renderSlide({
        dropzone,
        wrapper,
        editorHtmlData,
        activeElement,
        updateHistory,
        requestHierarchyUpdate,
        deActiveElement,
      });

      keybaordHandler(updateHistory).moveElement()
      keybaordHandler(updateHistory).deleteDialog(dispatchEditorActions.openDeleteLayerDailog)

      handleScaleToFit();
      if(activeEdtiableID && document.getElementById(activeEdtiableID)){
        document.getElementById(activeEdtiableID).classList.add('active')
      }
    } catch (e) {
      console.error(e);
      setError({
        message: "Oops ! something went wrong.",
        error: "Presentation HTML may be invalid!",
      });
    }

    return cleanUp;
  }, [editorHtmlData, mainWrapperRef, dropzoneRef]);

  // ----EXPORTING...
  const handleSlideExport = () => {

    setSaveLoading(true);
    const dropzone = dropzoneRef.current;
    const saveDataDiv = saveDataRef.current;
    //hide all popups before screenshot of slide
    document.querySelectorAll(".popup-component").forEach((el) => {
      el.style.display = "none";
      el.nextSibling.style.display = "none";
    });
    // Filtering dropzone and appending it to saveData element (for capture and saving purpose)
    let filterDropzoneNode = cleanDropzone(dropzone);
    let isSlideBackgroundChanged = masterTemplateUi.isSlideBackgroundChanged(filterDropzoneNode)
    // return true;
    if(isMasterSave){
      filterDropzoneNode.querySelectorAll('.cs-layer:not(.masterTemplate)').forEach(element => element.remove());
    }else{
      /*if master template is connected and master template background override with slide 
      background then dont delete masterTemplate object keep them for thumbnail */
      if(isSlideBackgroundChanged === false){
        // console.log('background not change')
        filterDropzoneNode.querySelectorAll('.masterTemplate').forEach(element => element.remove());
      }else{
        // console.log('background change')
      }
      filterDropzoneNode =  masterTemplateUi.removeTemplateBGBeforeSaveSlide(filterDropzoneNode);  
    }

    filterDropzoneNode = masterTemplateUi.removeHelperAttr(filterDropzoneNode)
    // return true
    filterDropzoneNode.querySelectorAll(".cs-graph").forEach((el) => {
      // changing graph id since chartJS lib is dependent on id, changing to prevent id duplication
      el.id = getRandomId();
    });

    //Need to remove src to take screenshot
    filterDropzoneNode.querySelectorAll(".cs-video video").forEach((el) => {
      // changing graph id since chartJS lib is dependent on id, changing to prevent id duplication
      el.setAttribute('src','');
    });
    
    // rendering it's functionality because graph, carousel and functional components are not rendered !
    saveDataDiv.innerHTML = "";
    saveDataDiv.append(filterDropzoneNode);
    renderEditableElements({
      layersContainer: filterDropzoneNode,
      forCapture: true,
    });
     
    // after filter making it visible take it's screen shot
    saveDataDiv.style.display = "block";
    // A setTimeout is implemented since waiting for graph to render !
    // console.log('filterDropzoneNode ', filterDropzoneNode)
    setTimeout(() => {
      takeScreenShot(filterDropzoneNode).then(async (res) => {
        const [image, thumbnail] = res;
        // re appending and cleaning again because it became functional above
        saveDataDiv.innerHTML = "";
        const filterDropzoneNode = cleanDropzone(dropzone);
        saveDataDiv.append(filterDropzoneNode);
        // after screenshot clear the saveDiv and make api call
        await onSave(saveDataDiv, image, thumbnail);
        // then clearing our div because it's job is closed
        saveDataDiv.innerHTML = "";
      }).catch((error) => {
        // Handle any errors that might occur during the screenshot process
        console.error("Screenshot process failed:", error);
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: "Screenshot process failed",
          })
        );
        // You can perform additional actions based on the specific error
      }).finally(()=>{
        setSaveLoading(false);
      })
    }, 100);
  };

  // For opening and closing confirmation dialog
  const toggleConfirmationDialog = (open = true, type) => {
    setConfirmNavigation((prev) => ({
      type: type ? type : prev.type,
      open,
    }));
  };
  
  let masterTemplateOnOrOFF = ''
  if(!isMasterTemplatePage){
    if(editMasterTemplateOnSlide){
      masterTemplateOnOrOFF = 'masterTemplateOn';
    }else{
      masterTemplateOnOrOFF = 'masterTemplateOff';
    }
  }
  
  return (
    <>
      <Box className="editorPage">
        {isLoading ? (
          <div className="fullpage">
            <Typography variant="h1">Loading {title}</Typography>
          </div>
        ) : error ? (
          <div className="fullpage">
            <Typography className="error">{error.message}</Typography>
            <Typography className="error">({error.error})</Typography>
          </div>
        ) : (
          saveLoading && (
            <div className="fullpage">
              {isAnyChange? (
                <Typography variant="h1">Saving {title}...</Typography>
              ): (
                <Typography variant="h1">Generating Thumbnails..</Typography>
              )}
            </div>
          )
        )}

        {/* Editor Header */}
        <Box className="topbar-height">
          <TopBar
            zoom={zoom}
            scaledToFit={scaledToFit}
            dimenssions={dimenssions}
            isAnyChange={isAnyChange}
            component={props.component}
            handleZoomChange={handleZoomChange}
            handleSlideExport={handleSlideExport}
            updateHistory={updateHistory}
            activeElement={activeElement}
            masterTemplate={masterTemplate}
            isMasterTemplatePage={isMasterTemplatePage}
            editMasterTemplateOnSlide={editMasterTemplateOnSlide}
          />
        </Box>
        {/*Editor Body */}
        <Box className="editorBody">
          <Grid container spacing={1}>
            <Grid item md={2}>
              <Card classes="editorCard leftbar">
                <Leftbar
                  requestHierarchyUpdate={requestHierarchyUpdate}
                  dropzoneRef={dropzoneRef}
                  component={props.component}
                  zoom={zoom}
                  editMasterTemplateOnSlide={editMasterTemplateOnSlide}
                />
              </Card>
            </Grid>
            <Grid item md={7.5}>
              <Card classes="editorCard main">
                <div
                  className="mainWrapper presentation-editor"
                  id="mainWrapper"
                  ref={mainWrapperRef}
                >
                  {/* EDITOR */}
                  <div
                    id="dropzone"
                    className={`cs-slide editable ${masterTemplateOnOrOFF}`} 
                    ref={dropzoneRef}
                  ></div>
                  <div
                    id="saveData"
                    // style={{ display: "none" }}
                    ref={saveDataRef}
                  >
                  </div>
                  <div className="gridOverylay" id="gridOverylay"></div>
                </div>
              </Card>
            </Grid>
            <Grid item md={2.5}>
              <Card classes="editorCard rightbar">
                <Rightbar
                  type={type}
                  id={presentationId}
                  slideId={slideId}
                  component="presentation"
                  ComponentProperties={RighatbarProperties}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      
      <DeleteLayerDialogs />
      <Confirm
        isOpen={confirmNavigation.open}
        alert={confirmNavigation.type == "alert"}
        subtitle={
          confirmNavigation.type == "alert"
            ? "Don't forget to save your changes , if you go back, your changes will be discarded without any confirmation, Please be careful before leaving !"
            : "If you go Back, Your Changes will be lost !"
        }
        confirmText="Yes"
        cancelText="No"
        handleConfirm={() => {
          callBackRef.current();
        }}
        handleClose={() => {
          toggleConfirmationDialog(false);
        }}
      />
     
    </>
  );
};
export default Editor;
