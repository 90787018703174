import React, { useState, useEffect } from "react";
import Content from "src/layouts/Content";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Menu,
} from "@mui/material";
import ViewContact from "./ViewContact";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccounts,
  fetchContacts,
  filterContact,
} from "src/store/actions/campaign";
import "../../../styles/campaign/campaign.scss";
import UploadContact from "./UploadContact";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Contact = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, SetSelectedAccount] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchContacts());
    dispatch(fetchAccounts()).then((res) => {
      setAccounts(res.payload);
    });
  }, [dispatch]);

  const campaignContacts = useSelector((state) => state.campaign.contacts);

  const filterChangeHandler = (e) => {
    dispatch(filterContact(e.target.value));
    SetSelectedAccount(e.target.value);
  };

  const createContact = () => {
    navigate("create", { state: { selectedAccount } });
  };

  return (
    <>
      <Content
        title="Contacts"
        headerAction={
          <>
            <FormControl
              className="filterContact"
              sx={{ minWidth: 200, margin: "0px 0px" }}
            >
              <InputLabel sx={{ fontSize: "14px" }}>Account</InputLabel>
              <Select
                name="accounts"
                value={selectedAccount}
                label="Account"
                onChange={filterChangeHandler}
              >
                {accounts &&
                  accounts.map((data) => {
                    return (
                      <MenuItem
                        value={`${data._id}`}
                        key={`${data._id}`}
                      >{`${data.name}`}</MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <Button
              onClick={handleClick}
              variant="contained"
              className="campaignBtn"
              endIcon={<MdOutlineKeyboardArrowDown size={15} />}
            >
              Contact
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={createContact}>Create Contact</MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setShow(true);
                }}
              >
                Upload Conatct
              </MenuItem>
            </Menu>
          </>
        }
      ></Content>

      <Grid className="assetTable" style={{ mt: 2 }}>
        <Grid item xs={12}>
          <TableContainer className="campaignTable" component={Paper}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead className="tableHead">
                <TableRow>
                  <TableCell className="tableHeading">First Name</TableCell>
                  <TableCell className="tableHeading">Last Name</TableCell>
                  <TableCell className="tableHeading">Email</TableCell>

                  <TableCell className="tableHeading tableAction">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {campaignContacts &&
                  campaignContacts.map((data) => {
                    return (
                      <ViewContact
                        key={data._id}
                        id={data._id}
                        firstName={data.firstName}
                        lastName={data.lastName}
                        email={data.email}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <UploadContact
        selectAccount={selectedAccount}
        show={show}
        close={() => setShow(false)}
      />
    </>
  );
};

export default Contact;
