import React, { useState, useEffect } from "react";
import { Checkbox, FormControl, IconButton, MenuItem, Select } from "@mui/material";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import Select_Box from "src/components/common/Select_Box";
import Button from "src/components/Button";
import { BiPauseCircle, BiPlayCircle } from "react-icons/bi";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";

const dropDown = [
  {
    label: "bounce",
    value: "animate__bounce",
  },
  {
    label: "flash",
    value: "animate__flash",
  },
  {
    label: "pulse",
    value: "animate__pulse",
  },
  {
    label: "rubberBand",
    value: "animate__rubberBand",
  },
  {
    label: "shakeX",
    value: "animate__shakeX",
  },
  {
    label: "shakeY",
    value: "animate__shakeY",
  },
  {
    label: "headShake",
    value: "animate__headShake",
  },
  {
    label: "swing",
    value: "animate__swing",
  },
  {
    label: "tada",
    value: "animate__tada",
  },
  {
    label: "wobble",
    value: "animate__wobble",
  },
  {
    label: "jello",
    value: "animate__jello",
  },
  {
    label: "heartBeat",
    value: "animate__heartBeat",
  },
  {
    label: "backInDown",
    value: "animate__backInDown",
  },
  {
    label: "backInLeft",
    value: "animate__backInLeft",
  },
  {
    label: "backInRight",
    value: "animate__backInRight",
  },
  {
    label: "backInUp",
    value: "animate__backInUp",
  },
  {
    label: "backOutDown",
    value: "animate__backOutDown",
  },
  {
    label: "backOutLeft",
    value: "animate__backOutLeft",
  },
  {
    label: "backOutRight",
    value: "animate__backOutRight",
  },
  {
    label: "backOutUp",
    value: "animate__backOutUp",
  },
  {
    label: "bounceIn",
    value: "animate__bounceIn",
  },
  {
    label: "bounceInDown",
    value: "animate__bounceInDown",
  },
  {
    label: "bounceInLeft",
    value: "animate__bounceInLeft",
  },
  {
    label: "bounceInRight",
    value: "animate__bounceInRight",
  },
  {
    label: "bounceInUp",
    value: "animate__bounceInUp",
  },
  {
    label: "bounceOut",
    value: "animate__bounceOut",
  },
  {
    label: "bounceOutDown",
    value: "animate__bounceOutDown",
  },
  {
    label: "bounceOutLeft",
    value: "animate__bounceOutLeft",
  },
  {
    label: "bounceOutRight",
    value: "animate__bounceOutRight",
  },
  {
    label: "bounceOutUp",
    value: "animate__bounceOutUp",
  },
  {
    label: "fadeIn",
    value: "animate__fadeIn",
  },
  {
    label: "fadeInDown",
    value: "animate__fadeInDown",
  },
  {
    label: "fadeInDownBig",
    value: "animate__fadeInDownBig",
  },
  {
    label: "fadeInLeft",
    value: "animate__fadeInLeft",
  },
  {
    label: "fadeInLeftBig",
    value: "animate__fadeInLeftBig",
  },
  {
    label: "fadeInRight",
    value: "animate__fadeInRight",
  },
  {
    label: "fadeInRightBig",
    value: "animate__fadeInRightBig",
  },
  {
    label: "fadeInUp",
    value: "animate__fadeInUp",
  },
  {
    label: "fadeInUpBig",
    value: "animate__fadeInUpBig",
  },
  {
    label: "fadeInTopLeft",
    value: "animate__fadeInTopLeft",
  },
  {
    label: "fadeInTopRight",
    value: "animate__fadeInTopRight",
  },
  {
    label: "fadeInBottomLeft",
    value: "animate__fadeInBottomLeft",
  },
  {
    label: "fadeInBottomRight",
    value: "animate__fadeInBottomRight",
  },
  {
    label: "fadeOut",
    value: "animate__fadeOut",
  },
  {
    label: "fadeOutDown",
    value: "animate__fadeOutDown",
  },
  {
    label: "fadeOutDownBig",
    value: "animate__fadeOutDownBig",
  },
  {
    label: "fadeOutLeft",
    value: "animate__fadeOutLeft",
  },
  {
    label: "fadeOutLeftBig",
    value: "animate__fadeOutLeftBig",
  },
  {
    label: "fadeOutRight",
    value: "animate__fadeOutRight",
  },
  {
    label: "fadeOutRightBig",
    value: "animate__fadeOutRightBig",
  },
  {
    label: "fadeOutUp",
    value: "animate__fadeOutUp",
  },
  {
    label: "fadeOutUpBig",
    value: "animate__fadeOutUpBig",
  },
  {
    label: "fadeOutTopLeft",
    value: "animate__fadeOutTopLeft",
  },
  {
    label: "fadeOutTopRight",
    value: "animate__fadeOutTopRight",
  },
  {
    label: "fadeOutBottomRight",
    value: "animate__fadeOutBottomRight",
  },
  {
    label: "fadeOutBottomLeft",
    value: "animate__fadeOutBottomLeft",
  },
  {
    label: "flip",
    value: "animate__flip",
  },
  {
    label: "flipInX",
    value: "animate__flipInX",
  },
  {
    label: "flipInY",
    value: "animate__flipInY",
  },
  {
    label: "flipOutX",
    value: "animate__flipOutX",
  },
  {
    label: "flipOutY",
    value: "animate__flipOutY",
  },
  {
    label: "lightSpeedInRight",
    value: "animate__lightSpeedInRight",
  },
  {
    label: "lightSpeedInLeft",
    value: "animate__lightSpeedInLeft",
  },
  {
    label: "lightSpeedOutRight",
    value: "animate__lightSpeedOutRight",
  },
  {
    label: "lightSpeedOutLeft",
    value: "animate__lightSpeedOutLeft",
  },
  {
    label: "rotateIn",
    value: "animate__rotateIn",
  },
  {
    label: "rotateInDownLeft",
    value: "animate__rotateInDownLeft",
  },
  {
    label: "rotateInDownRight",
    value: "animate__rotateInDownRight",
  },
  {
    label: "rotateInUpLeft",
    value: "animate__rotateInUpLeft",
  },
  {
    label: "rotateInUpRight",
    value: "animate__rotateInUpRight",
  },
  {
    label: "rotateOut",
    value: "animate__rotateOut",
  },
  {
    label: "rotateOutDownLeft",
    value: "animate__rotateOutDownLeft",
  },
  {
    label: "rotateOutDownRight",
    value: "animate__rotateOutDownRight",
  },
  {
    label: "rotateOutUpLeft",
    value: "animate__rotateOutUpLeft",
  },
  {
    label: "rotateOutUpRight",
    value: "animate__rotateOutUpRight",
  },
  {
    label: "hinge",
    value: "animate__hinge",
  },
  {
    label: "jackInTheBox",
    value: "animate__jackInTheBox",
  },
  {
    label: "rollIn",
    value: "animate__rollIn",
  },
  {
    label: "rollOut",
    value: "animate__rollOut",
  },
  {
    label: "zoomIn",
    value: "animate__zoomIn",
  },
  {
    label: "zoomInDown",
    value: "animate__zoomInDown",
  },
  {
    label: "zoomInLeft",
    value: "animate__zoomInLeft",
  },
  {
    label: "zoomInRight",
    value: "animate__zoomInRight",
  },
  {
    label: "zoomInUp",
    value: "animate__zoomInUp",
  },
  {
    label: "zoomOut",
    value: "animate__zoomOut",
  },
  {
    label: "zoomOutDown",
    value: "animate__zoomOutDown",
  },
  {
    label: "zoomOutLeft",
    value: "animate__zoomOutLeft",
  },
  {
    label: "zoomOutRight",
    value: "animate__zoomOutRight",
  },
  {
    label: "zoomOutUp",
    value: "animate__zoomOutUp",
  },
  {
    label: "slideInDown",
    value: "animate__slideInDown",
  },
  {
    label: "slideInLeft",
    value: "animate__slideInLeft",
  },
  {
    label: "slideInRight",
    value: "animate__slideInRight",
  },
  {
    label: "slideInUp",
    value: "animate__slideInUp",
  },
  {
    label: "slideOutDown",
    value: "animate__slideOutDown",
  },
  {
    label: "slideOutLeft",
    value: "animate__slideOutLeft",
  },
  {
    label: "slideOutRight",
    value: "animate__slideOutRight",
  },
  {
    label: "slideOutUp",
    value: "animate__slideOutUp",
  },
];

const Animation = ({ elementId, updateHistory, undoRedoChange }) => {
  const element = document.getElementById(elementId);

  const [animationcheckbox, setAnimationcheckbox] = useState(false);
  const initialState = {
    play: false,
    type: "animate__flash",
    duration: 1,
    delay: 0,
    infinite: false,
    event: "slideeneter",
  };
  const [animateproperties, setAnimateProperties] = useState(initialState);
  
  const handleStopTyping = useDebouncedCallback(() => {
    updateHistory();
  }, 1000);

  useEffect(() => {
    const animation = element.getAttribute("data-animation");
    const animationType = element.getAttribute("data-animation-type");
    const animationDuration = element.getAttribute("data-animation-duration");
    const animationDelay = element.getAttribute("data-animation-delay");
    const animationInfinite = element.getAttribute("data-infinite");
    const animationEvent = element.getAttribute("data-event");
    if (animation != null) {
      const durationSplit = animationDuration.split("s")[0];
      const delaySplit = animationDelay.split("s")[0];
      setAnimationcheckbox(true);
      setAnimateProperties((prev) => ({
        ...prev,
        type: animationType,
        duration: durationSplit,
        delay: delaySplit,
        event: animationEvent,
      }));
    } else {
      setAnimateProperties(initialState);
      setAnimationcheckbox(false);
    }

    if (animationInfinite != null) {
      if (animationInfinite === "true") {
        setAnimateProperties((prev) => ({ ...prev, infinite: true }));
      } else {
        setAnimateProperties((prev) => ({ ...prev, infinite: false }));
      }
    }
    return () => {
      element.classList.remove("animate__animated");
      element.classList.remove(animateproperties.type);
      element.style.removeProperty("--animate-duration");
      element.style.removeProperty("animation-delay");
    };
  }, [elementId, undoRedoChange]);

  const animationHandler = (e) => {
    setAnimationcheckbox(!animationcheckbox);
    const checked = e.target.checked;
    const animateType = animateproperties.type;
    const animateDuration = `${animateproperties.duration}s`;
    const animateDelay = `${animateproperties.delay}s`;
    const animateInfinite = animateproperties.infinite;
    const animateEvent = animateproperties.event;
    if (checked === true) {
      element.setAttribute("data-animation", checked);
      element.setAttribute("data-animation-type", animateType);
      element.setAttribute("data-animation-duration", animateDuration);
      element.setAttribute("data-animation-delay", animateDelay);
      element.setAttribute("data-infinite", animateInfinite);
      element.setAttribute("data-event", animateEvent);
    } else {
      element.removeAttribute("data-animation");
      element.removeAttribute("data-animation-type");
      element.removeAttribute("data-animation-duration");
      element.removeAttribute("data-animation-delay");
      element.removeAttribute("data-infinite");
      element.removeAttribute("data-event");
      element.classList.remove("animate__animated");
      element.classList.remove(animateproperties.type);
      element.style.removeProperty("--animate-duration");
      element.style.removeProperty("animation-delay");
      element.classList.remove("animate__infinite");
    }
    updateHistory();
  };
  
  useEffect(() => {
    const animateType = animateproperties.type;
    const animateDuration = `${animateproperties.duration}s`;
    const animateDelay = `${animateproperties.delay}s`;
    if (animateproperties.play) {
      element.classList.add("animate__animated");
      element.classList.add(animateType);
      element.style.setProperty("--animate-duration", animateDuration);
      element.style.setProperty("animation-delay", animateDelay);
      element.addEventListener("animationend", () => {
        element.classList.remove("animate__animated");
        element.classList.remove(animateType);
        element.style.removeProperty("--animate-duration");
        element.style.removeProperty("animation-delay");
        setAnimateProperties((prev) => ({ ...prev, play: false }));
      });
    } else {
      element.classList.remove("animate__animated");
      element.classList.remove(animateType);
      element.style.removeProperty("--animate-duration");
      element.style.removeProperty("animation-delay");
    }
    // updateHistory()
  }, [animateproperties.play, animateproperties.type, animateproperties.delay, animateproperties.duration])
  
  const playhandler = (e) => {
    setAnimateProperties((prev) => ({ ...prev, ['play']: true }));
  };

  const typeHandler = (e) => {
    const { name, value } = e.target;
    setAnimateProperties((prev) => ({ ...prev, [name]: value }));
    const lastType = element.getAttribute("data-animation-type");
    if (animateproperties.play === true) {
      element.classList.remove(lastType);
      element.classList.add("animate__animated");
      element.classList.add(value);
    }
    element.setAttribute("data-animation-type", value);
    setTimeout(function(){
      updateHistory();
    }, 100)
  };

  const durationHandler = (e) => {
    const { name, value } = e.target;

    setAnimateProperties((prev) => ({ ...prev, [name]: value }));
    element.setAttribute("data-animation-duration", `${value}s`);
    if (animateproperties.play === true) {
      element.style.setProperty("--animate-duration", `${value}s`);
    }

    handleStopTyping()
  };

  const delayHandler = (e) => {
    const { name, value } = e.target;
    setAnimateProperties((prev) => ({ ...prev, [name]: value }));
    element.setAttribute("data-animation-delay", `${value}s`);
    if (animateproperties.play === true) {
      element.style.setProperty("animation-delay", `${value}s`);
    }
    handleStopTyping()
  };

  const infiniteHandler = (e) => {
    const { name, value, checked } = e.target;
    setAnimateProperties((prev) => ({ ...prev, [name]: checked }));
    element.setAttribute("data-infinite", checked);
    updateHistory();
  };

  const eventHandler = (e) => {
    const { name, value } = e.target;
    setAnimateProperties((prev) => ({ ...prev, [name]: value }));
    element.setAttribute("data-event", value);
    updateHistory();
  };

  return (
    <Control heading="Animation" isCollapse={true} className="animationControl" >
      <Row
        label="Enable"
        component="h3"
        element={
          <Check_Box
            name="animationCheckbox"
            color="secondary"
            size="medium"
            checked={animationcheckbox}
            onClick={animationHandler}
          />
        }
      />

      <Row
        label="Play Animation"
        element={
          <IconButton onClick={playhandler} size="small" color="secondary" name="play" disabled={!animationcheckbox}>{!animateproperties.play ? <BiPlayCircle /> : <BiPauseCircle /> } </IconButton>
        }
      />

      <Row
        label="Type"
        element={
          <Select_Box
            name="type"
            value={animateproperties.type}
            onChange={typeHandler}
            displayEmpty
            options={dropDown}
            disabled={!animationcheckbox}
          />
        }
      />

      <Row
        label="Duration"
        element={
          <input
            type="number"
            name="duration"
            className="input"
            step="0.1"
            min="0.1"
            value={animateproperties.duration}
            onChange={durationHandler}
            disabled={!animationcheckbox}
          />
        }
      />

      <Row
        label="Delay"
        element={
          <input
            type="number"
            name="delay"
            className="input"
            step="0.1"
            min="0"
            value={animateproperties.delay}
            onChange={delayHandler}
            disabled={!animationcheckbox}
          />
        }
      />

      <Row
        label="Infinite"
        element={
          <Check_Box
            name="infinite"
            color="secondary"
            size="small"
            checked={animateproperties.infinite}
            onChange={infiniteHandler}
            disabled={!animationcheckbox}
            toolTipText="Infinite only work on preview mode"
          />
        }
      />

      <Row
        label="Event"
        element={
          <Select_Box
            name="event"
            value={animateproperties.event}
            onChange={eventHandler}
            options={[
              { label: "Slide Enter", value: "slideeneter" },
              { label: "Tap", value: "tap" },
            ]}
            disabled={!animationcheckbox}
          />
        }
      />
    </Control>
  );
};

export default Animation;
