import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import Select_Box from "src/components/common/Select_Box";

const FlipCard = (props) => {
  const { elementId, undoRedoChange, updateHistory } = props;

  const flipCard = document.getElementById(elementId);

  const flipCardInner = flipCard.querySelector(".flipCardInner");
  const flipCardBack = flipCard.querySelector(".flipCardBack");

  const [isFlipped, setIsFlipped] = useState(false);
  const [type, setType] = useState("horizontal");

  useEffect(() => {
    const direction = flipCard.getAttribute("data-flip-direction");
    setType(direction);
    if (flipCardInner.style.transform.includes("180")) {
      setIsFlipped(true);
    } else {
      setIsFlipped(false);
    }
  }, [elementId, undoRedoChange]);

  const flipHandler = (e) => {
    const direction = flipCard.getAttribute("data-flip-direction");
    const check = e.target.checked;
    setIsFlipped(check);

    if (direction == "horizontal") {
      if (flipCardInner.style.transform.includes("180")) {
        flipCardInner.style.transform = "rotateX(0deg)";
      } else {
        flipCardInner.style.transform = "rotateX(180deg)";
        flipCardBack.style.transform = "rotateX(180deg)";
      }
    } else {
      if (flipCardInner.style.transform.includes("180")) {
        flipCardInner.style.transform = "rotateY(0deg)";
      } else {
        flipCardInner.style.transform = "rotateY(180deg)";
        flipCardBack.style.transform = "";
      }
    }
    updateHistory()
  };

  const typeHandler = (e) => {
    setType(e.target.value);
    flipCard.setAttribute("data-flip-direction", e.target.value);
    updateHistory()
  };

  return (
    <Control
      heading="Actions"
      className="actions"
      divider={false}
      isCollapse={true}
      expanded={true}
    >
      <Row
        label="Type"
        element={
          <Select_Box
            value={type}
            onChange={typeHandler}
            options={[
              { label: "Horizontal", value: "horizontal" },
              { label: "Vertical", value: "vertical" },
            ]}
          />
        }
      />
      <Row
        label={isFlipped ? 'Goto front': 'Goto Back'}
        element={
          <Check_Box
            name="flip"
            color="secondary"
            size="small"
            checked={isFlipped}
            onChange={flipHandler}
          />
        }
      />
    </Control>
  );
};

export default FlipCard;
