import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "src/interfaces/api.interface";
import HttpService from "src/services/http.service";
import { uiActions } from "../reducers/ui-slice";

export const getAllCompanyUsers = createAsyncThunk(
  "getAllCompanyUsers",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.getAllCompanyUsers(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createUser = createAsyncThunk(
  "createUser",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.createUser(),
        data,
        thunkAPI,
        undefined,
        true
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Created!",
            message: "User created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk(
  "getUser",
  async ({ id, companyId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.getUser(companyId, id),
        undefined,
        thunkAPI
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userVerify = createAsyncThunk(
  "userVerify",
  async ( thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.userVerify(),
        undefined,
        thunkAPI
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.updateUser(id),
        data,
        thunkAPI,
        undefined,
        true
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Edited!",
          message: "User Edited successfully",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.user.deleteUser(id),
        null,
        thunkAPI
      );

      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Deleted!",
          message: "User Deleted successfully",
        })
      );
      return { id };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
