import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Editor from "src/components/editor/presentation/Editor";
import {
  getSlide,
  saveSlide,
  getMasterTemplate,
  saveMasterTemplate,
} from "src/store/actions/presentations";
import AccessControl from "../../components/presentations/AccessControl";
import {
  getNewAssetsAndHTML,
  masterTemplateUi,
  parseHTMLString,
  updateDomAssetsPath,
} from "src/services/editor.service";
import $ from "jquery";
import { Breadcrumbs, Typography } from "@mui/material";
import { changeAssetsUrlOfHTML, PreloadImage, PreloadImages } from "src/components/editor/presentation/renderPresentation";
import { editorActions } from "src/store/reducers/editor-slice";

const PresentationEditor = () => {
  const dispatch = useDispatch();
  const { id: presentationId, slideId, masterTemplateId } = useParams();
  const [loading, setLoading] = useState(true);
  
  // Fetching Slide's Data
  useEffect(async () => {
    if (!masterTemplateId) {
      await dispatch(getSlide({ presentationId, slideId }));
    } else {
      await dispatch(getMasterTemplate({ presentationId, masterTemplateId }));
    }
    setLoading(false);
  }, []);

  // Presentation's Data
  const {
    editorHtmlData,
    editorMTHtmlData,
    presentationSlides: slides,
    presentation,
    selectedMasterTemplate
  } = useSelector((state) => state.presentations);
  
  const { editMasterTemplateOnSlide } = useSelector((state) => state.editor);
  const title = masterTemplateId || editMasterTemplateOnSlide ? "Master Template" : "Slide";
  const {
    bucketURL,
    sourceTemplate,
    name: presentationName,
    masterTemplates,
  } = presentation;

  const baseUrl = bucketURL + "/" + sourceTemplate + "/";

  //is slide edit
  const activeSlide = slides.find((slide) => slide.id == slideId);
  //is master template edit
  let activeMasterTemplate = masterTemplates?.find((masterTemplate) => masterTemplate._id == masterTemplateId);
  if(editMasterTemplateOnSlide){
    activeMasterTemplate = selectedMasterTemplate;
  }
  //move to s3 presentation bucket url
  const isMasterSave = editMasterTemplateOnSlide || masterTemplateId;
  const moveToUrl = (elementType, fileName) => {
    if (isMasterSave) {
      return `assets/masterTemplates/${activeMasterTemplate?.fileId}/${elementType}/${fileName}`;
    }
    return `assets/slides/${activeSlide?.fileId}/${elementType}/${fileName}`;
  };
  // Saving Slide's Data
  const saveDropzoneData = (saveSlideDiv, image, thumbnail) => {
    const {
      assets,
      defaultAssets,
      uploadedAssets,
      HTML: assetsHtml,
    } = getNewAssetsAndHTML(saveSlideDiv, moveToUrl, baseUrl, isMasterSave);

    const data = {
      HTML: assetsHtml,
      thumbnail,
      assets,
      defaultAssets,
      image,
    };
    // return false;
    
    if (isMasterSave) {
      const MasterTemplateId = masterTemplateId || selectedMasterTemplate?._id;
      return dispatch(
        saveMasterTemplate({
          presentationId,
          masterTemplateId:MasterTemplateId,
          data,
        })
      ).then(() => {
        //once  masterTemplate is saved then update the real dom by changing the asset path from general asset to s3 asset
        // updateDomAssetsPath(uploadedAssets);
        dispatch(editorActions.setEditMasterTemplateOnSlide(false))
      });
    } else {
      return dispatch(
        saveSlide({
          presentationId,
          slideId,
          data,
        })
      ).then((res) => {
        if(res?.meta.arg.data){
          const newElements = [...res?.meta.arg.data.assets, ...res?.meta.arg.data.defaultAssets]
          setTimeout(function(){
            PreloadImages(newElements);
          },1000)
        }
      });
    }
  };
  
  let editerHTML = '';
  const [HTML, setHTML] = useState("")
  useEffect(() => {
    let formattedSlideHTML = null;
    let formattedMasterTemplateHTML = null;
    if(editorHtmlData){
      formattedSlideHTML = changeAssetsUrlOfHTML(editorHtmlData, baseUrl)
    }

    if(editorMTHtmlData){
      formattedMasterTemplateHTML = changeAssetsUrlOfHTML(editorMTHtmlData, baseUrl, "template");
    }
    
    // if user preview master template
    if(masterTemplateId){
      editerHTML = formattedMasterTemplateHTML;
    }else{
    // if user on slide
      // if slide and master template found
      if (formattedSlideHTML && formattedMasterTemplateHTML) {
        editerHTML = masterTemplateUi.mergeMasterTemplateIntoSlide(formattedSlideHTML, formattedMasterTemplateHTML)
      }
      // if only slide  found
      if(formattedSlideHTML && !formattedMasterTemplateHTML){
        editerHTML = formattedSlideHTML
      }
      // if only master template  found
      if(!formattedSlideHTML && formattedMasterTemplateHTML){
        // editerHTML = formattedMasterTemplateHTML
        editerHTML = masterTemplateUi.appendTemplateStyle(formattedMasterTemplateHTML)
      }
    }

    setHTML(editerHTML)
  }, [editorHtmlData, editorMTHtmlData])
  
  return (
    <AccessControl component="edit">
      {activeSlide || activeMasterTemplate ? (
        <Editor
          type="presentation"
          component="presentation"
          editorHtmlData={HTML}
          isLoading={loading}
          onSave={saveDropzoneData}
          title={title}
          masterTemplate={activeMasterTemplate}
          isMasterTemplatePage={masterTemplateId}
          isMasterSave={isMasterSave}
          editMasterTemplateOnSlide={editMasterTemplateOnSlide}
        />
      ) : (
        <>No {title} Data were found !</>
      )}
    </AccessControl>
  );
};
export default React.memo(PresentationEditor);
