import React, { useEffect, useState } from "react";
//Carousel
import { Carousel } from "react-responsive-carousel";
//Stlyes
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { masterTemplateUi } from "src/services/editor.service";
import { BsTablet } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { BiCommentDetail, BiZoomIn, BiZoomOut } from "react-icons/bi";
import Comments from "../../components/dialogs/Comments";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../src/styles/editor.scss";
import "../../../src/styles/components.scss";
import "../../../src/styles/Preview.scss";
import { clearPresentationData } from "src/store/reducers/presentations-slice";
import { MdFitScreen, MdOutlineFitScreen } from "react-icons/md";
import {
  csRenderFunctional,
  siteMapActions,
  renderSitemap,
  getDimenssions,
  scaleDropzone,
  renderWithActions,
  renderWithAnimation
} from "src/components/editor/editorLogics/components";
import { formatHTML, hasPermission } from "src/utils/Utils";
import { changeAssetsUrlOfHTML } from "src/components/editor/presentation/renderPresentation";
import { getMasterTemplate, getSlide } from "src/store/actions/presentations";
import Breadcrumb from "src/components/editor/presentation/topBar/Breadcrumb";
import CircleLoader from "src/components/ui/loader/CircleLoader";

const reRenderWithFunctions = (layer, i) => {
  const editables = layer.find("[class*=cs-]");

  if (!layer[0].className.includes("audio") && !layer[0].className.includes("video")) {
    csRenderFunctional(layer);
  }
  
  editables.each(function () {
    csRenderFunctional($(this));
  });
};

const Preview = (props) => {
  const dispatch = useDispatch();
  const { masterTemplateId } = useParams();
  const { onlyPreview, isLoadingApprove, isLoadingDeclined, versionId } = props;
  const type = "presentation";
  const {
    preview: { isReviewed, firstSlideId },
  } = useSelector((state) => state.presentations);
  const [activeSlideIndex, setActiveSlideIndex] = useState(null);
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [shouldZoomShow, setshouldZoomShow] = useState(false);
  const [scaledToFit, setScaledToFit] = useState(false);
  //zoom code
  const [zoom, setZoom] = useState("1");
  const { list: versions } = useSelector((state) => state.versions);
  let {
    presentation,
    preview,
    editorHtmlData,
    editorMTHtmlData,
    presentationSlides
  } = useSelector((state) => state.presentations);

  const getVersionSlides = () => {
    const chapterArr = versions.filter(e => e._id == preview.versionId)[0]?.chapters?.map((chapter) => {
      if (chapter?.slides && chapter?.status) {
        return chapter?.slides.map((slideObj) => ({
          ...slideObj,
          chapterName: chapter.name,
        }));
      }
    });
    //2.then pushing required and structured data..
    const slides = [];
    chapterArr?.forEach((slidesArr) => {
      slidesArr?.forEach((slide) => {
        slides.push({
          id: slide._id,
          thumbnail: slide.thumbnail,
          chapterName: slide.chapterName,
          name: slide.name,
        });
      });
    });
    return slides;
  }

  //if versionId exist then get slides from versions, else get slides from presentation
  let slides = JSON.parse(JSON.stringify(versionId ? getVersionSlides() : presentationSlides));
  if(!versionId){
    slides = slides.filter((slide) => slide.isEnabled)
  }

  const handleCloseComments = () => {
    setIsOpenComments(false);
  };

  const handleOpenComments = () => {
    setIsOpenComments(true);
  };
  const paramsId = useParams().id;
  let  slideId  = useParams().slideId || firstSlideId;
  const isOnDialog = paramsId == undefined;
  const presentationId = paramsId || props.presentationId;

  const [loading, setLoading] = useState(true);

  const {
    targetDevice,
    bucketURL,
    sourceTemplate,
    _id: fetchedId,
  } = presentation;
  const baseURL = bucketURL + "/" + sourceTemplate + "/";

  const updateRoute = (switchedSlide) => {
    history.replaceState(presentationId, "", switchedSlide);
  };

  const isNotHaveData = (activeSlide) => {
    const slide = activeSlide?.querySelector(".cs-slide");
    const noDataDiv = activeSlide?.querySelector(".item-message");
    if (slide !== null || noDataDiv !== null) {
      return false;
    }
    return true;
  };
  useEffect( () => {
    if(masterTemplateId){
       dispatch(getMasterTemplate({ presentationId, masterTemplateId })).then(()=> setLoading(false))
    }
  }, [masterTemplateId])
  
  useEffect(() => {
    if (slides.length && !masterTemplateId) {
      console.log('activeSlideIndex', activeSlideIndex)
      const slide = slides[activeSlideIndex];
      if (activeSlideIndex !== null && slide) {
        let switchedSlide = versionId ? `${slide.id}/${versionId}` : `${slide.id}`;
        // const activeSlide = document.querySelector(`.slide.selected .item`);
        const activeSlide = document.querySelector(`.slide:nth-child(${activeSlideIndex+1}) .item`)
        updateSlideInBreadCrumb(activeSlide.getAttribute('data-name'))
        if(activeSlide.classList.contains('fetched')){
          activeSlide.querySelectorAll('.cs-layer').forEach((element)=>{
            renderWithAnimation(element)
          })
        }else{
          if (versions.length > 0) {
            let dataList = [...versions]
            const latest = dataList.sort((a, b) => Number(b.version) - Number(a.version))[0];
            if (latest._id == versionId) {
              switchedSlide = slide.id;
            }
          }
          setLoading(true)
          dispatch(getSlide({presentationId, slideId:switchedSlide})).then(()=>{
            setLoading(false)
            activeSlide?.classList.add('fetched')
          })
        }
      }
    }
  }, [activeSlideIndex, presentation]);
  
  useEffect(() => {
    if (slideId) {
      const urlActiveSlide = slides.findIndex((slide) => slide.id == slideId);
      setActiveSlideIndex(urlActiveSlide);
    } else {
      setActiveSlideIndex(0);
    }
  }, [presentation]);

  useEffect(() => {
    if (presentationId === fetchedId) {
      if (editorMTHtmlData !== null) {
        //remove blank lines
        editorMTHtmlData = formatHTML(editorMTHtmlData);
        //update assets url
        editorMTHtmlData = changeAssetsUrlOfHTML(editorMTHtmlData, baseURL, 'template');
      }
      
      if (editorHtmlData !== null) {
        //remove blank lines
        editorHtmlData = formatHTML(editorHtmlData);
        //update assets url
        editorHtmlData = changeAssetsUrlOfHTML(editorHtmlData, baseURL);
      }
    
      let previewHtml = null
      // if user preview master template
      if(masterTemplateId && editorMTHtmlData){
        previewHtml = editorMTHtmlData
      }else{
      // if user on slide
        // if slide and master template found
        if(editorHtmlData !== null && editorMTHtmlData !== null){
          previewHtml = masterTemplateUi.mergeMasterTemplateIntoSlide(editorHtmlData, editorMTHtmlData)
        }
        // if only slide  found
        if(editorHtmlData !== null && editorMTHtmlData === null){
          previewHtml = editorHtmlData
        }
        // if only master template  found
        if(editorHtmlData === null && editorMTHtmlData !== null){
          previewHtml = editorMTHtmlData
        }
      }
      const activeSlideSelector = `.slide:nth-child(${activeSlideIndex+1}) .item`
      const activeSlide = $(activeSlideSelector);
      activeSlide.html("");
      activeSlide.css("width", "100%");
      
      if (previewHtml) {
        const { height, width } = getDimenssions(props.component, targetDevice || "ipad");
          
          previewHtml = previewHtml?.replace(`id="dropzone"`, "");
          activeSlide.append(previewHtml);
          const csSlide = activeSlide.children();
          csSlide.css({ width, height });
          setshouldZoomShow(true);

          // All layers in active item
          const csLayers = activeSlide.find(".cs-layer");
          // modifying all layers after appending in dropzone or preview
          csLayers.each(function (i) {
            // $(this).attr("id", "preview-" + i);
            reRenderWithFunctions($(this), i);
          });
          // For Sitemap Modal
          if (slides.length && !slides[0].thumbnail.includes("http")) {
            slides.forEach((obj) => {
              obj.thumbnail = bucketURL + "/" + obj.thumbnail;
            });
          }
          // For Sitemap Modal
          siteMapActions.slides = slides;
          siteMapActions.switchSlide = (index) => {
            handleChange(index, true);
          };
          activeSlide.css("width", "");
          
          // renderSitemap(null, null, null, activeSlideSelector+' .cs-slide');
          renderSitemap(null, null, null, '.slider-wrapper');
          handleScaleToFit();
        
      }else{
        activeSlide.append(
          `<div class="item-message">No Preview Found<div>`
        );
      }
    }
  }, [loading]);

  // To clear editor's data
  const clearData = () => {
    dispatch(clearPresentationData());
  };

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);
  

  const updateSlideInBreadCrumb = (slideName)=>{
    const ele = document.querySelector('#breadCrumb .textLimit')
    console.log('updateSlideInBreadCrumb', ele);
    ele.innerHTML = slideName
  }

  const handleChange = (currentIndex, checkEditable = false) => {
    siteMapActions.closeModal();
    const previousSlide = document.querySelector(".slide.previous");

    if (previousSlide) {
      previousSlide.querySelectorAll("video").forEach((video) => video.pause());
      previousSlide.querySelectorAll("audio").forEach((audio) => audio.pause());
    }
    
    const activeSlideItem = document.querySelectorAll("li.slide .item")[currentIndex];
    const activeSlideName = activeSlideItem.getAttribute('data-name')
    // document.getElementById('breadCrumb').innerHTML = activeSlideName 
    if ((!activeSlideItem.querySelector(".editable") && !activeSlideItem.querySelector(".item-message")) || checkEditable ) {
      const switchedSlide = slides[activeSlideIndex].id;

      if (!isOnDialog) {
        updateRoute(switchedSlide);
      }
      setActiveSlideIndex(+currentIndex);
      if (isNotHaveData(activeSlideItem)) {
        clearData();
        setLoading(true);
      }
    }
    setshouldZoomShow(activeSlideItem.querySelector(".editable") !== null);
  };

  const handleZoomChange = (value) => {
    const activeSlide = document.querySelector(".slide.selected");

    if (value == "scaleToFit") {
      handleScaleToFit();
    } else {
      const minZoom = 1;
      const maxZoom = 250;
      if (value < minZoom) {
        value = minZoom;
      } else if (value > maxZoom) {
        value = maxZoom;
      }
      const zoom = value / 100;
      scaleDropzone(
        activeSlide.querySelector(".item"),
        activeSlide.querySelector(".cs-slide"),
        zoom
      );
      setScaledToFit(false);
      setZoom(value);
    }
  };

  const handleScaleToFit = () => {
    const activeSlide = document.querySelector(".slide.selected");
    if (activeSlide) {
      scaleDropzone(
        activeSlide,
        activeSlide.querySelector(".cs-slide"),
        "scaleToFit",
        (scale) => {
          let zoom = Math.round(scale * 100);
          setZoom(zoom);
        }
      );
      setScaledToFit(true);
    }
  };

  if (onlyPreview) {
    const { slideRef: ref, onSwipe } = props;
    return (
      <Box className="preview-wrapper preview-kpi">
        <div
          id="preview"
          className={`preview-slide ${loading ? "preview-loading" : ""} ${masterTemplateId? 'masterTemplate':''}`}
        >
          <Carousel
            ref={ref}
            showThumbs={false}
            showArrows
            swipeable
            emulateTouch
            onSwipeStart={(e) =>
              onSwipe(
                e,
                activeSlideIndex,
                slides.length - 1 === activeSlideIndex
              )
            }
            onChange={handleChange}
            selectedItem={activeSlideIndex}
          >
            {slides.map((el, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="item"></div>
                  {loading && (
                    <div className="item-loading"> <CircleLoader /> </div>
                  )}
                </React.Fragment>
              );
            })}
          </Carousel>
        </div>
      </Box>
    );
  }

  return (
    <>
      {isOpenComments && (
        <Comments
          isOpen={isOpenComments}
          handleClose={handleCloseComments}
          id={presentationId}
          slideId={slides[activeSlideIndex].id}
          type={type}
        />
      )}
      <div className="container-preview">
        <div className="preview-head flex-between">
          <div className="flex-item-sm">
            <Breadcrumb />
          </div>
          <div className="flex-item-fit">
            {props.viewType === "editor" && (
              <>
                <Grid item>
                  <Typography variant="body1" component="span" className="text">
                    Target Device :
                    <Typography
                      variant="body1"
                      component="span"
                      ml={1}
                      sx={{ verticalAlign: "middle" }}
                    >
                      <BsTablet />
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    {targetDevice}
                  </Typography>
                </Grid>
              </>
            )}
            {shouldZoomShow && (
              <>
                <Box container className="zoom-selectbox">
                  <IconButton onClick={() => handleZoomChange(+zoom - 1)}>
                    <BiZoomOut />
                  </IconButton>
                  <TextField
                    type="number"
                    value={zoom}
                    onChange={(e) => handleZoomChange(e.target.value)}
                  />
                  <IconButton onClick={() => handleZoomChange(+zoom + 1)}>
                    <BiZoomIn />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    onClick={() => handleZoomChange("scaleToFit")}
                  >
                    {scaledToFit ? <MdFitScreen /> : <MdOutlineFitScreen />}
                  </IconButton>
                </Box>
              </>
            )}
          </div>
          <div className="flex-item-sm">
            <div className="w-100 text-end  ">
              {(slides[activeSlideIndex] && !masterTemplateId) && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleOpenComments}
                  startIcon={<BiCommentDetail color="#2E3A94" size={17} />}
                >
                  Comments
                </Button>
              )}
              {/* It means the component is dependent on dialog then show close button */}
              {isOnDialog && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => props.handleClose()}
                  startIcon={<AiOutlineClose color="#2E3A94" size={17} />}
                >
                  Close
                </Button>
              )}
            </div>
          </div>
        </div>
        {!isReviewed && hasPermission('approvalProcess') && (
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              justifyContent="center"
              className="preview-head"
            >

              {isLoadingApprove ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <Button
                  variant="text"
                  color="success"
                  size="large"
                  onClick={() => props.reviewPresentation(true)}
                  loading={true}
                >
                  Approve
                </Button>
              )}

              {isLoadingDeclined ? (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              ) : (
                <Button
                  variant="text"
                  color="error"
                  size="large"
                  onClick={() => props.reviewPresentation(false)}
                >
                  Declined
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        {activeSlideIndex !== null && (
          <Box className="preview-wrapper">
            <div
              id="preview"
              className={`preview-slide ${loading ? "preview-loading" : ""} ${masterTemplateId? "masterTemplate":''}`}
            >
              <Carousel
                showThumbs={false}
                swipeable={masterTemplateId? false: true}
                onChange={handleChange}
                selectedItem={activeSlideIndex}
              >
                {slides.map((el, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="item" data-name={el.name}></div>
                      {loading && (
                        <div className="item-loading"> <CircleLoader /> </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </Carousel>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default Preview;
