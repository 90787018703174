import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetMoveFolderbreadcrumbs } from 'src/store/reducers/folder-slice';
import { getMoveableFoldersList } from 'src/store/actions/folder';
import { getModuleType } from 'src/utils/Utils';
import TextLimit from "../ui/TextLimit";

const MoveFolderBreadcumbs = ({ setSelectedFolder }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const moduleType = getModuleType();
  const folderId = searchParams.get("folderId");
  const { moveFolderBreadcrumbs } = useSelector((state) => state.folders);

  const openFolder = (_id) => {
    if (setSelectedFolder) {
      setSelectedFolder(_id);
    }
  };

  const handleLibraryClick = () => {
    dispatch(resetMoveFolderbreadcrumbs());
    setSelectedFolder(null);
    // Fetch root folders here if necessary
    dispatch(getMoveableFoldersList({ parentId: null, type: moduleType }));
  };

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {moveFolderBreadcrumbs.length > 0 ? (
        <Link component="button" color="inherit" onClick={handleLibraryClick}>
          My Library
        </Link>
      ) : (
        <Typography color="text.primary">My Library</Typography>
      )}
      {moveFolderBreadcrumbs.map((folder, index) => {
        const isLast = index === moveFolderBreadcrumbs.length - 1;

        return isLast ? (
          <Typography color="text.primary" key={folder._id}>
            <TextLimit width="70%" text={folder.name} />
          </Typography>
        ) : (
          <Link
            component="button"
            color="inherit"
            onClick={() => openFolder(folder._id)}
            key={folder._id}
          >
            <TextLimit width="70%" text={folder.name} />
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default MoveFolderBreadcumbs;
