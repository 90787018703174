import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Control from "../Control";
import ImageProperties from "./ImageProperties";
import "src/styles/properties.scss";
import AssetUpload from "../AssetUpload";

const ImageBackground = (props) => {
  const {
    elementId,
    updateHistory,
    bgColorCheckbox,
    selector,
    undoRedoChange,
    bgImageCheckbox,
    isBgImageExist,
  } = props;
  const [src, setSrc] = useState("");
  
  // const element = selector
  //   ? document.getElementById(elementId).querySelectorAll(`${selector}`)
  //   : [document.getElementById(elementId)];

  const onSrcUpdate = (url) => {
    setSrc(url);
  };
  
  return (
    <>
      <Control className="background" divider={false}>
        <Grid container wrap="nowrap" className="backgroundselectbox">
          <Grid item md={12} className="imageUploadgrid">
            <AssetUpload
              isCollapse={false}
              elementId={elementId}
              assetFormat="image"
              updateType="background"
              updateHistory={updateHistory}
              onSrcUpdate={onSrcUpdate}
            />
          </Grid>
        </Grid>
      </Control>
      <ImageProperties
        elementId={elementId}
        bgColorCheckbox={bgColorCheckbox}
        updateHistory={updateHistory}
        undoRedoChange={undoRedoChange}
        src={src || isBgImageExist}
      />
    </>
  );
};

export default ImageBackground;
