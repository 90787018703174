import React, { useEffect, useState } from "react";
import Control from "./Control";
import { Box, Card, Typography } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";
import "src/styles/properties.scss";
import "src/styles/styles.scss";
import Row from "src/components/properties/Row";
import Select_Box from "src/components/common/Select_Box";
import { AiOutlineDelete } from "react-icons/ai";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";

const SocialIcons = ({ elementId, updateHistory, undoRedoChange }) => {
  const element = document.getElementById(elementId);

  const elementStyles = getComputedStyle(element.querySelector("img"));

  const [icons, setIcons] = useState([]);
  const [allIcons, setAllIcons] = useState([]);
  const [iconType, setIconType] = useState({
    radius: elementStyles.borderRadius,
    filter: elementStyles.filter,
    size: 0,
    gap: 0,
  });
  const all = [
    { icon: "facebook", href: "https://facebook.com" },
    { icon: "instagram", href: "https://instagram.com" },
    { icon: "twitter", href: "https://twitter.com" },
    { icon: "youtube", href: "https://youtube.com" },
    { icon: "linkedin", href: "https://linkedin.com" },
    { icon: "github", href: "https://github.com" },
  ];
  useEffect(() => {
    const tempIcons = [];
    const achor = element.querySelectorAll("a");

    achor.forEach((elem) => {
      tempIcons.push({ icon: elem.dataset.iconName, href: elem.href });
    });
    setIcons(tempIcons);
    let temp = [];
    for (let i = 0; i < all.length; i++) {
      const cur = all[i];
      const found = tempIcons.find((elem) => elem.icon === cur.icon);
      if (!found) {
        temp.push({ icon: cur.icon, href: cur.href });
      }
    }
    setAllIcons(temp);
    setIconType({
      radius: elementStyles.borderRadius,
      filter: elementStyles.filter,
      gap: achor[0].style.marginRight
        ? achor[0].style.marginRight.split("px")[0]
        : 0,
      size: +elementStyles.width.split("px")[0],
    });
  }, [elementId, undoRedoChange]);

  element
    .querySelectorAll("a")
    .forEach((elem) =>
      elem.addEventListener("click", (e) => e.preventDefault())
    );

  const addIcon = (icon, index) => {
    element.innerHTML += ` <a data-icon-name="${icon.icon}" href="${icon.href}" target="_blank" style="width:${iconType.size}px ; font-size: 15.5556px; margin:0 ${iconType.gap}px; display: inline-block;"> 
    <img src="https://createmart-seed-folder.s3.amazonaws.com/placeholders/social-comp/${icon.icon}.png" style=" width: 100%; border-radius:${iconType.radius}; filter:${iconType.filter}">
    </a>`;
    let items = [...allIcons];
    items.splice(index, 1);
    setAllIcons(items);
    setIcons((prev) => [...prev, { icon: icon.icon, href: icon.href }]);
    updateHistory();
  };
  
  const handleStopTyping = useDebouncedCallback(() => {
    updateHistory({
      id: elementId,
    });
  }, 1000);

  const hrefHandler = (event, index) => {
    const { value } = event.target;
    let items = [...icons];
    let item = { ...items[index] };
    item.href = value;
    items[index] = item;
    setIcons(items);
    const links = element.querySelectorAll("a");
    links[index].setAttribute("href", value);
    handleStopTyping()
  };

  const deleteIcon = (icon, index) => {
    if (icons.length > 1) {
      let items = [...icons];
      items.splice(index, 1);
      setIcons(items);
      setAllIcons((prev) => [...prev, { icon: icon.icon, href: icon.href }]);
      const links = element.querySelectorAll("a");
      links[index].remove();
      updateHistory();
    }
  };

  const iconTypeHandler = (event, type) => {
    const { value } = event.target;
    setIconType((prev) => ({ ...prev, [type]: value }));
    const images = element.querySelectorAll("img");
    if (type === "radius") {
      images.forEach((element) => (element.style.borderRadius = value));
    } else {
      images.forEach((element) => (element.style.filter = value));
    }
    updateHistory();
  };

  const iconGapHandler = (event) => {
    const { value } = event.target;
    const achors = element.querySelectorAll("a");
    if (!value) {
      achors.forEach((achor) => {
        achor.style.margin = `0px 0px`;
      });
      setIconType((prev) => ({ ...prev, gap: 0 }));
    } else if (value >= 0) {
      achors.forEach((achor) => {
        achor.style.margin = `0px ${value}px`;
      });
      setIconType((prev) => ({ ...prev, gap: value }));
    }
    handleStopTyping()
  };

  const iconSizeHandler = (event) => {
    const { value } = event.target;
    const achors = element.querySelectorAll("a");
    if (value < 22) {
      achors.forEach((anchor) => {
        anchor.style.width = "22px";
      });
      setIconType((prev) => ({ ...prev, size: 22 }));
    } else {
      achors.forEach((anchor) => {
        anchor.style.width = `${value}px`;
      });
      setIconType((prev) => ({ ...prev, size: value }));
    }
    handleStopTyping()
  };

  return (
    <>
      <Control
        heading="Social Links"
        className="social-icons"
        isCollapse={true}
        divider={false}
        expanded={true}
      >
        <Box className="social-links">
          {icons.map((item, index) => (
            <Card key={index} variant="outlined" sx={{background:'#f0f0f0'}} className="social-box">
              <Box className="social-box-head">
              <Typography variant="h3">{item.icon} </Typography>
              <AiOutlineDelete
                className={`cancelIcon ${icons.length === 1 ? "disabled" : ""}`}
                onClick={() => deleteIcon(item, index)}
              />
              </Box>
              
              <input
                onChange={(e) => hrefHandler(e, index)}
                type="text"
                value={item.href}
              />
            </Card>
          ))}
        </Box>
        <Box className="socialicons-container">
          {allIcons.length != 0 && (
            <Typography>Click the icons to add</Typography>
          )}
          <Box className="icons">
            {allIcons.map((val, ind) => {
              return (
                <React.Fragment key={ind}>
                  <img
                    src={`https://createmart-seed-folder.s3.amazonaws.com/placeholders/social-comp/${val.icon}.png`}
                    onClick={() => addIcon(val, ind)}
                  />
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </Control>
      <Control
        heading="Icon Styles"
        className="social-icons"
        isCollapse={true}
        divider={false}
        expanded={false}
      >
        <Row
          label="Icon Radius"
          element={
            <Select_Box
              value={iconType.radius}
              onChange={(e) => iconTypeHandler(e, "radius")}
              options={[
                { label: "Square", value: "0%" },
                { label: "Rounded", value: "4px" },
                { label: "Circle", value: "50%" },
              ]}
            />
          }
        />
        <Row
          label="Icon Type"
          element={
            <Select_Box
              value={iconType.filter}
              onChange={(e) => iconTypeHandler(e, "filter")}
              options={[
                { label: "Normal", value: "none" },
                { label: "Black", value: "grayscale(1)" },
              ]}
            />
          }
        />
        <Row
          label="Icon Gap"
          element={
            <input
              type="number"
              className="input"
              value={iconType.gap}
              onChange={iconGapHandler}
            />
          }
        />

        <Row
          label="Icon Size"
          element={
            <input
              type="number"
              min="22"
              className="input"
              value={iconType.size}
              onChange={iconSizeHandler}
            />
          }
        />
      </Control>
    </>
  );
};

export default SocialIcons;
