import React, { useState, useEffect } from "react";
import { Checkbox, FormControl, Select, MenuItem } from "@mui/material";
import UnitBoxes from "./unitBoxes/UnitBoxes";
import { convertRgbAToHexAplha } from "src/services/editor.service";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";
import Select_Box from "src/components/common/Select_Box";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useSetLayerHeight } from "../editorLogics/components";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";

const Border = (props) => {
  const {
    elementId,
    updateHistory: propsUpdHistory,
    undoRedoChange,
    selector,
  } = props;
  const element = selector
    ? document.getElementById(elementId).querySelectorAll(`${selector}`)
    : [document.getElementById(elementId)];
  // default border Values
  const [borderEnable, setBorderEnable] = useState(false);

  const [border, setborder] = useState({
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "0096ff",
    borderShown: false,
  });
  // default Border Radius Properties

  // Getting Element's Style
  useEffect(() => {
    const { borderWidth, borderStyle, borderColor } = element[0].style;

    if (borderStyle && borderStyle !== "none") {
      // If element has border then show element style
      setBorderEnable(true);
      setborder({
        borderWidth: borderWidth ? borderWidth.split("px")[0] : 0,
        borderStyle,
        borderColor: convertRgbAToHexAplha(borderColor).hex,
        borderShown: true,
      });
    } else {
      // else show default styles
      setBorderEnable(false);
      setborder({
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#0096ff",
        borderShown: false,
      });
    }
  }, [elementId, undoRedoChange]);

  // Appliying Css
  useEffect(() => {
    if (borderEnable) {
      element.forEach((elem) => {
        elem.style.borderWidth = `${border.borderWidth}px`;
        elem.style.borderStyle = border.borderStyle;
        elem.style.borderColor = border.borderColor;
      });
      useSetLayerHeight(element);
    }
  }, [border, borderEnable]);

  const updateHistory = ({
    action = HISTORY_ACTIONS.STYLED_ACTIONS.common,
    ...rest
  } = {}) => {
    propsUpdHistory({ action, ...rest });
  };

  const handleStopTyping = useDebouncedCallback(() => {
    updateHistory();
  }, 1000);
  //get input values
  const inputHandler = (e) => {
    const { name, value, checked } = e.target;
    if (name === "borderShown") {
      setborder((prev) => ({ ...prev, [name]: checked }));
      updateHistory();
    } else if (name === "borderColor") {
      element.forEach((elem) => {
        elem.style.borderColor = value;
      });
      setborder((prev) => ({ ...prev, [name]: value }));
      handleStopTyping();
    } else if (name === "borderWidth") {
      if (value <= 10) {
        setborder((prev) => ({ ...prev, [name]: value }));
        handleStopTyping();
      }
    } else {
      setborder((prev) => ({ ...prev, [name]: value }));
      handleStopTyping();
    }
  };

  const borderStyle = [
    { label: "Solid", value: "solid" },
    { label: "Dashed", value: "dashed" },
    { label: "Dotted", value: "dotted" },
  ];

  return (
    <>
      <Control
        heading="Border"
        divider={false}
        className="borderProperties"
        isCollapse={true}
      >
        {/* show border or not */}
        {props.border && (
          <>
            <Row
              label="Border"
              component="h3"
              element={
                <Check_Box
                  name="borderShown"
                  color="secondary"
                  size="medium"
                  checked={borderEnable}
                  onChange={(e) => {
                    console.log(e.target.checked);
                    if (e.target.checked === false) {
                      element.forEach((elem) => {
                        elem?.style?.removeProperty("border-width");
                        elem?.style?.removeProperty("border-style");
                        elem?.style?.removeProperty("border-color");
                        elem?.style?.removeProperty("border");
                      });
                      updateHistory();
                    }

                    setBorderEnable(!borderEnable);
                  }}
                />
              }
            />
            {/* All Border Styles */}
            {borderEnable && (
              <>
                {/* change color of border */}
                <Row
                  label="Border Color"
                  element={
                    <input
                      type="color"
                      name="borderColor"
                      className="color"
                      value={border.borderColor}
                      onChange={inputHandler}
                    />
                  }
                />
                {/* Changing Border's Width */}
                <Row
                  label="Border's Width"
                  element={
                    <input
                      type="number"
                      name="borderWidth"
                      className="input"
                      value={border.borderWidth}
                      onChange={inputHandler}
                      min="0"
                    />
                  }
                />
                {/* Changing Border's Style */}
                <Row
                  label="Border's Style"
                  element={
                    <Select_Box
                      name="borderStyle"
                      value={border.borderStyle}
                      onChange={inputHandler}
                      options={borderStyle}
                    />
                  }
                />
              </>
            )}
          </>
        )}
        {/* Border Radius Property Starts Here */}
        {props.borderRadius && (
          <UnitBoxes
            selector={selector ? selector : `#${elementId}`}
            property="border-radius"
            heading="Border Radius"
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        )}
      </Control>
    </>
  );
};
Border.defaultProps = {
  borderRadius: true,
  border: true,
};
export default Border;
