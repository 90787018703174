import {
  getLayersEditableChildren,
  insertHtmlToDropzone,
  editableActions,
  setLayerElementIds,
  gridActions,
  deActiveElementOnOtherSideClick,
} from "src/services/editor.service";
import { getRandomId } from "src/services/utils";
import { Sortable } from "../editorLogics/sortable";
import $ from "jquery";
import { HISTORY_ACTIONS } from "src/data/contants";

export const attachlisteners = ({
  element,
  activeElement,
  updateHistory,
  actionState,
  requestHierarchyUpdate,
}) => {
  const isDropzone = element.classList.contains("cs-slide");
  // Redering Main element (can be dropzone or a layer)
  element.classList.add("editable");
  
  if (isDropzone) {
    // <----------------------Dropzone------------------------>
    element.addEventListener("dblclick", (e) => {
      e.stopPropagation();
      editableActions.clickHandler({ element, activeElement });
    });
    element.style.zoom = 1
  } else {
    // <----------------------Layer------------------------>
    // Click listener
    const addEventListeners = (element, isLayer) => {
      element.addEventListener("click", (e) => {
        e.preventDefault()
        e.stopPropagation();
        editableActions.clickHandler({ e, element, activeElement });
      });
      // mouse listeners
      element.addEventListener("mouseover", (e) => {
        e.stopPropagation();
        editableActions.mouseOverHandler({ e, element });
      });
      element.addEventListener("mouseout", (e) => {
        editableActions.mouseOutHandler({ e, element });
      });

      if (isLayer) {
        new Sortable(element, {
          preventedContainers: "toolbar",
          containers: "cs-grid-item,cs-slide",
          zoomedElement: document.getElementById('dropzone'),
          onSort: (itemDetails) => {
            setTimeout(() => {
              updateHistory({
                action: HISTORY_ACTIONS.sorted,
                actionState,
                id: "", // since extraInfo is passing, id isn't required
                extraInfo: itemDetails,
              });
              requestHierarchyUpdate();
            }, 300);
          },
        });

        if (element.classList.contains("grid-component")) {
          const historyCallback = (id, _, extraInfo) => {
            // waiting for JQuery to remove dragging classes
            setTimeout(() => {
              updateHistory({
                action: HISTORY_ACTIONS.gridResized,
                actionState,
                id,
                extraInfo,
              });
            }, 30);
          };
          gridActions.resizeable(element, historyCallback);
        }
      }
    };
    // rendering layer 2nd param, isLayer?
    addEventListeners(element, true);

    // Redering it's nested children if they are editable if not a dropzone
    const nestedEditables = getLayersEditableChildren(element);
    nestedEditables.forEach((nestedEditable) => {
      nestedEditable.classList.add("editable");
      addEventListeners(nestedEditable);
      // check if element has no inline style padding, then get computed style padding and set it as inline style padding
      // console.log('nestedEditable.style.padding', nestedEditable.style.padding, typeof(nestedEditable.style.padding), nestedEditable.style.padding === '')
      if( nestedEditable.style.padding === ''){
        nestedEditable.style.padding = getComputedStyle(nestedEditable).padding
      }
    });
  }
};

export const applyPadding = (
  el,
  deviceDimensions = {
    width: "600px",
    height: "600px",
  }
) => {
  const isPresentationEditor = el.closest("#mainWrapper");
  if (isPresentationEditor) {
    const editable = $(el);
    const padding = 6;
    //now apply padding class on editable element
    const setPadding = (element) => {
      // if element has not styled padding
      if (
        !element.attr("style") ||
        !element.attr("style").includes("padding")
      ) {
        element.css("padding", padding);
      }
    };
    // set for layer and child elements
    const layer = editable.closest(".cs-layer");
    setPadding(layer);
    if (editable.find(".editable").length || editable.hasClass("cs-col")) {
      setPadding(editable); // do not set for last nested editable
    }
  }
};

export const csRenderEmailEditable = ({
  element,
  updateHistory,
  activeElement,
  actionState,
  requestHierarchyUpdate,
}) => {
  console.log('element', element)
    // applying unique id
  if (!element.classList.contains("cs-slide")) {
    setLayerElementIds(element);

    if (!element.id) {
      element.id = getRandomId();
    }
  }

  // if not have status then append it first
  if (!element.querySelector(".status")) {
    element.insertAdjacentHTML(
      "beforeend",
      "<div class='status'><span></span></div>"
    );
  }
  // Preventing default for anchor tags
  const a = element.tagName === "A" ? element : element.querySelector("a");
  if (a) {
    a.draggable = false;
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // Preventing default for image tags
  const img =
    element.tagName === "IMG" ? element : element.querySelector("img");
  if (img) {
    img.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // removing unnecessary stylings
  element.style.transform = "";

  // Adding listeners
  attachlisteners({
    element,
    activeElement,
    updateHistory,
    actionState,
    requestHierarchyUpdate,
  });
};

export const renderEditableElements = ({
  layersContainer,
  activeElement = () => {},
  updateHistory = () => {},
  actionState,
  requestHierarchyUpdate = () => {},
}) => {
  const csLayers = layersContainer.querySelectorAll(".cs-layer");

  // Rendering container itself first
  csRenderEmailEditable({
    element: layersContainer,
    actionState,
    activeElement,
    updateHistory,
    requestHierarchyUpdate,
  });
  // Rendering Layers
  csLayers.forEach((layer, i) => {
    // now bnding funcationality to layers
    csRenderEmailEditable({
      element: layer,
      actionState,
      activeElement,
      updateHistory,
      requestHierarchyUpdate,
    });
  });

  requestHierarchyUpdate();
};

export const renderEmail = ({
  dropzone,
  editorHtmlData,
  activeElement,
  updateHistory,
  actionState,
  requestHierarchyUpdate,
  deActiveElement,
} = {}) => {
  // Appending HTML
  insertHtmlToDropzone(dropzone, editorHtmlData);
  // Rendering with functionalities
  renderEditableElements({
    layersContainer: dropzone,
    activeElement,
    updateHistory,
    actionState,
    requestHierarchyUpdate,
  });

  return deActiveElementOnOtherSideClick(deActiveElement);
};
