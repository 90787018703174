import jsPDF from "jspdf";
import pdfPng from "src/assets/images/pdf.png";
import audioIcon from "src/assets/images/audioIcon.png";
import videoIcon from "src/assets/images/videoIcon.png";
import { createSearchParams } from "react-router-dom";
import { nav } from "src/layouts/sidebar/_nav";
import { roles as usersRoles } from "src/config";
import { isEditor } from "./editor.service";
import { removePx } from "src/utils/Utils";

export const dateFormat = (date) => {
  if (!date) {
    return false;
  }
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  date = Date.parse(date);
  return new Date(date).toLocaleDateString("en-US", options);
};

export const canSendRequest = (config, id, minutes = 5) => {
  const miliseconds = minutes * 60 * 1000;
  if (id == config.id && Date.now() < config.requestTime + miliseconds) {
    return false;
  }
  return true;
};

export const getFirstSlideId = (chapters) => {
  if (!chapters) {
    return;
  }
  const validChapter = chapters.find(
    (chapter) => chapter.status && chapter.slides.length
  );
  if (validChapter) {
    return validChapter.slides.find((s) => s.isEnabled)?._id;
  }
};

export const downloadbyUrl = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = "presentation.zip";
  link.click();
};

export const statusTypes = {
  draft: "Draft",
  reviewed: "Reviewed",
  expired: "Expired",
  declined: "Declined",
  inReview: "In Review",
  canceled: "Cancelled",
};

export const roles = {
  editorRU: "Editor",
  viewer: "Viewer",
  owner: "Owner",
};

//object to manage controllers on status review and in review
export const hideOnStatus = {
  reviewed: {
    edit: true,
  },
  inReview: {
    edit: true,
  },
};

export const sharingData = [
  {
    id: "firstName",
    numeric: false,
    sort: true,
    label: "NAME",
  },
  {
    id: "lastName",
    numeric: true,
    sort: true,
    label: "LAST NAME",
  },
  {
    id: "role",
    numeric: true,
    sort: true,
    label: "ROLE",
  },
  {
    id: "email",
    numeric: true,
    sort: true,
    label: "EMAIL",
  },
];

export const versionData = [
  {
    id: "version",
    numeric: false,
    sort: true,
    label: "VERSION",
  },
  {
    id: "createdAt",
    numeric: true,
    sort: true,
    label: "DATE",
  },
  {
    id: "createdBy",
    numeric: true,
    sort: true,

    label: "CREATED BY",
  },
  {
    id: "status",
    numeric: true,
    sort: true,
    label: "STATUS",
  },
];

export const downloadPdfByImages = (images, resolution, downloadFileName = 'download') => {
  let { height, width } = resolution;
  //decrease the height and width
  let descreaseRatio = width >= 1900? 2.3 : 1.3
  height = height / descreaseRatio
  width = width / descreaseRatio
  const config = {
    orientation: height > width ? "p" : "l",
    unit: "px",
    hotfixes: ["px_scaling"],
    format: [width, height],
  };
  
  const doc = new jsPDF(config);

  images.forEach((image, index) => {
    doc.addImage(image, "JPEG", 0, 0, width, height);
    //not adding page if the page is last
    if (index !== images.length - 1) {
      doc.addPage();
    }
  });
  
  doc.save(`${downloadFileName}.pdf`);
};
export const downloadPdfByImages3 = (images) => {
  const config = {
    orientation: "l",
    unit: "px",
    hotfixes: ["px_scaling"],
    format: [1280, 960],
  };
  const doc = new jsPDF(config);

  images.forEach((image, index) => {
    doc.addImage(image, "JPEG", 0, 0, 1280, 960);
    //not adding page if the page is last
    if (index !== images.length - 1) {
      doc.addPage();
    }
  });

  doc.save("download.pdf");
};

export const downloadPdfByImages2 = (images) => {
  const config = {
    orientation: "l",
    unit: "mm",
    format: [278, 200],
  };
  const doc = new jsPDF(config);

  images.forEach((image, index) => {
    doc.addImage(image, "JPEG", 0, 0, 278, 200);
    //not adding page if the page is last
    if (index !== images.length - 1) {
      doc.addPage();
    }
  });

  doc.save("download.pdf");
};

export const checkIsLock = (status) => {
  if (status === "inReview" || status === "reviewed") {
    return true;
  }

  return false;
};

export const getThumbnailByFormat = (format, thumbnail) => {
  if (format) {
    format = format.toLowerCase();
  }
  const formats = {
    jpeg: thumbnail,
    jpg: thumbnail,
    png: thumbnail,
    jfif: thumbnail,
    mp3: audioIcon,
    ogg: audioIcon,
    wav: audioIcon,
    mp4: videoIcon,
    pdf: pdfPng,
  };

  return formats[format] || thumbnail;
};

export const getSpendedTime = (seconds = 0) => {
  let spendedWord = "second";
  let spendedTime = seconds;

  if (seconds > 59 && seconds < 3600) {
    spendedWord = "minute";
    spendedTime = seconds / 60;
  } else if (seconds > 3600) {
    spendedWord = "hour";
    spendedTime = seconds / 3600;
  }

  const timeWord = ` ${spendedWord}${spendedTime > 1 ? "s" : ""}`;
  return Math.round(spendedTime) + timeWord;
};

export const getFormattedTime = (time, formatFun = "toDateString") => {
  const formattedTime = new Date(time)[formatFun]();
  return formattedTime;
};

export const objToParams = (params) => {
  const queryObj = params;

  if (queryObj?.Language) {
    queryObj.language = queryObj.Language.split(",");
    delete queryObj.Language;
  }

  if (queryObj?.Country) {
    queryObj.country = queryObj.Country.split(",");
    delete queryObj.Country;
  }

  const query = createSearchParams({ ...queryObj }).toString();
  return `?${query}`;
};

export const getRolesEntry = (role) => {
  return usersRoles[role].entry;
};

export const getRolesPermission = (role, location) => {
  const { allowedUrls } = usersRoles[role];
  return allowedUrls.includes(location);
};

export const getRandomId = (prefix = "cs-") => {
  return `${prefix}${Math.floor(Math.random() * 1000000)}`;
};

export const getFileFormat = (url, dot = true) => {
  const match = url.match(/\.([a-zA-Z0-9]+)(\?.*)?$/);
  if(match){
    let format = match[1]
    if(dot){
      format = '.'+format;
    }
    return format;
  }else{
    return null
  }
}

export const posterOfVideo = (url) => {
  //get video format, video can be ogg, mp4 or mov, so its will replace any kind of format to jpg
  let format = getFileFormat(url);
  const newUrl =  url.replace('/assets/video','/assets/thumbnails').replace(format,'.jpg')
  return newUrl;
}

export const selectLast = (arr = []) => {
  return arr[arr.length - 1];
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const toCamelCase = (str) => {
  return str.split('-').map((word, index) => {
    return index === 0 ? word : capitalize(word);
  }).join('');
};

export const getImageSize = (src) => {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.src = src;

    img.onload = function() {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height }); // Resolve the promise with the dimensions
    };

    img.onerror = function() {
      reject(new Error('Image failed to load.'));
    };
  });
};
// setImageLayerWidth only for image-component inside all editors
export const setImageLayerWidth = async (image) => {
  if(!image.closest('.cs-layer').classList.contains('image-component')){
    return false;
  }
  
  try {
    let imageSrc = image.src
    const imageSize = await getImageSize(imageSrc); 
    if(imageSize && image.closest('.cs-layer')){
      const layer = image.closest('.cs-layer')
      const layerPaddingLeft = removePx(getComputedStyle(layer).paddingLeft)
      const layerPaddingRight = removePx(getComputedStyle(layer).paddingRight)
      const parent  = layer.parentElement  || document.getElementById('dropzone') ;
      
      const parentWidth = parent.offsetWidth - (layerPaddingLeft+layerPaddingRight);
      const imageWidth = imageSize.width;
      const imageHeight = imageSize.height;
      
      // setEle = if user on presentation then set layer width and height based on image height and width : else set image width not layer
      const setEle = isEditor.presentation() ? layer : image
      if(imageWidth > parentWidth){
        const scaleFactor = parentWidth / imageWidth;
        
        // Set the new width and height of the image based on the scaling factor
        const newImageWidth = parentWidth;  // Max width is parent width
        const newImageHeight = imageHeight * scaleFactor;  // Maintain aspect ratio

        // Apply the new dimensions to the image
        if(isEditor.presentation()){
          //if user on presentation then set width and height
          const distance = 20;
          setEle.style.width = (newImageWidth - distance) + 'px';
          setEle.style.height = (newImageHeight - distance) + 'px';
          setEle.style.left = (distance / 2) + 'px';
          setEle.style.top = (distance / 2) + 'px';
        }else{
          //if user on email or microsite then only set width
          setEle.style.width = newImageWidth + 'px';
        }
        
      }else{
        setEle.style.width = imageWidth+"px";
        if(isEditor.presentation()){
          setEle.style.height = imageHeight+"px";
        }  
      }
      
    }
    
  } catch (error) {
    console.error(error);
  }
};

export const layerAligment = (elementId, position) => {
  const layer = document.getElementById(elementId);
  const layerHeight = layer.offsetHeight; // For outer height
  const layerWidth = layer.offsetWidth; // For outer width

  const dropzone = layer.parentElement;
  const dropzoneHeight = dropzone.offsetHeight; // For outer height
  const dropzoneWidth = dropzone.offsetWidth; // For outer width

  const bottomPosition = dropzoneHeight - layer.offsetHeight + "px";
  const rightPosition = dropzoneWidth - layer.offsetWidth + "px";

  if (position === "top") {
    layer.style.top = "0px";
    layer.style.bottom = "";
  } else if (position === "bottom") {
    layer.style.top = bottomPosition;
  } else if (position === "left") {
    layer.style.left = "0px";
    layer.style.right = "";
  } else if (position === "right") {
    layer.style.left = rightPosition;
  } else if (position === "vc") {
    const top = dropzoneHeight / 2 - layerHeight / 2;
    layer.style.top = top + "px";
    layer.style.bottom = "";
  } else if (position === "hc") {
    const left = dropzoneWidth / 2 - layerWidth / 2;
    layer.style.left = left + "px";
    layer.style.right = "";
  }else if (position === "cc") {
    const top = dropzoneHeight / 2 - layerHeight / 2;
    const left = dropzoneWidth / 2 - layerWidth / 2;
    layer.style.left = left + "px";
    layer.style.right = "";
    layer.style.top = top + "px";
    layer.style.bottom = "";
  }
  layer.focus(); 
};