import HttpService from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { uiActions } from "../reducers/ui-slice";
import { api } from "../../interfaces/api.interface";

export const getCompanyData = createAsyncThunk(
  "getCompanyData",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.company.getCompanyData(id),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "updateCompany",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.company.updateCompany(id),
        data,
        thunkAPI
      );

      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Update!",
            message: "Company updated successfully",
          })
        );
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCompanyLogo = createAsyncThunk(
  "updateCompanyLogo",
  async (logo, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.company.updateCompanyLogo(),
        logo,
        thunkAPI
      );

      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Upload!",
            message: "Company logo uploaded successfully",
          })
        );
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCompanyLogo = createAsyncThunk(
  "deleteCompanyLogo",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.company.deleteCompanyLogo(),
        { id },
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Delete!",
            message: "Company logo deleted successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
